import React from "react";


import { useInput, FieldTitle } from "ra-core";
import { FormControl, InputLabel, Slider } from "@material-ui/core";




export default props => {

  const { resource, source, label,factor } = props;


  const {
    input: { value, onChange },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  const handleChange = (_,v) => {
  
   onChange(v);
  };

  const valueText=(value)=>{
  return factor?value*factor:value;
}

  return (
    <FormControl error={!!(touched && error)} fullWidth={true}>
      {label !== "" && label !== false && (
        <InputLabel shrink>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}


       <Slider
        value={value||props.max}
        onChange={handleChange}
        valueLabelFormat={valueText}
        aria-labelledby="discrete-slider"
    
        valueLabelDisplay="on"
       
        marks
        {...props}
      />

    </FormControl>
  );
};
