import * as React from "react";
import { FunctionComponent } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { useTranslate, useLogin, useNotify, useSafeSetState } from "ra-core";



import dataProvider from "cms-config/dataProvider";

interface Props {
    redirectTo?: string;
}



const useStyles = makeStyles(
    (theme: Theme) => ({
        form: {
            padding: "0 1em 1em 1em"
        },
        input: {
            marginTop: "1em"
        },
        button: {
            width: "100%"
        },
        icon: {
            marginRight: theme.spacing(1)
        }
    }),
    { name: "RaLoginForm" }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm: FunctionComponent<Props> = props => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const [resetMode, setResetMode] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);

    /*  const validate = (values: FormData) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            //errors.username = translate("ra.validation.required");
        }
        if (!values.password) {
            //errors.password = translate("ra.validation.required");
        }
        return errors;
    };*/

    const submit = values => {
        setLoading(true);
        if (resetMode) {
            dataProvider("CREATE", "password-reset", {
                data: values
            })
                .then(({ data }) => {
                    setLoading(false);
                    if (data.success) {
                        notify(
                            "We've sent the new password to your mailbox",
                            "success"
                        );
                        setResetMode(false);
                    }
                })
                .catch(error => {
                    setLoading(false);
                });
        } else {
            login(values, redirectTo)
                .then(() => {
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    notify(
                        typeof error === "string"
                            ? error
                            : typeof error === "undefined" || !error.message
                            ? "ra.auth.sign_in_error"
                            : error.message,
                        "warning"
                    );
                });
        }
    };

    return (
        <Form
            onSubmit={submit}
            // validate={validate}
            render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="username"
                                name="username"
                                component={Input}
                                label="Email address"
                                disabled={loading}
                            />
                        </div>
                        {resetMode ? null : (
                            <div className={classes.input}>
                                <Field
                                    id="password"
                                    name="password"
                                    component={Input}
                                    label={translate("ra.auth.password")}
                                    type="password"
                                    disabled={loading}
                                    autoComplete="current-password"
                                />
                            </div>
                        )}
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color={resetMode ? "secondary" : "primary"}
                            disabled={loading}
                            className={classes.button}
                            onClick={
                                resetMode
                                    ? e => {
                                          e.preventDefault();
                                          if (!loading) {
                                              submit(values);
                                          }
                                      }
                                    : null
                            }
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {resetMode
                                ? "Send password reset email"
                                : translate("ra.auth.sign_in")}
                        </Button>
                    </CardActions>

                    <Button
                        style={{
                            display:"block",
                            margin:"auto",
                            textTransform:"none"
                        }}
                        onClick={() => setResetMode(!resetMode)}
                    >
                        {resetMode ? "Back to login" : "Forgot Password?"}
                    </Button>
                </form>
            )}
        />
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string
};

export default LoginForm;
