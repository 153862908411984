import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    EditButton as RaEditButton, SaveButton,
    showNotification
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import ImageImage from "@material-ui/icons/Image";

import { fileUriToURl } from "cms-toolbox/helpers/utils";

import { siteUrl } from "cms-config/domain";

import Hashids from "hashids";
const hashids = new Hashids();

export const VisitButton = connect(
    null,
    {
        showNotification
        //  push
    }
)(
    class extends Component {
        render() {
            const { record, resource, alwaysShown, label, className } = this.props;

            let disabled=false
            if (!record) {
                if(alwaysShown){
                    disabled=true
                }else{
                    return null;
                }
            }

            let path;
            if (resource === "revision") {
                path = "concept/" + hashids.encode(1000000 + record.id);
            } else {
                path =
                    record._relations &&
                    record._relations.path &&
                    record._relations.path.length
                        ? record._relations.path[0].path
                        : record.path;
                if (typeof path !== "string") {
                    if (record?.id === "front") {
                        path = "";
                    } else if(!alwaysShown) {
                        return null;
                    }else{
                        disabled=true
                    }
                }
            }




            return (
                <Button
                    disabled={disabled}
                    title={label?null:"View on website"}
                    href={disabled?null:siteUrl + "/" + path}
                    target="_blank"
                    size="medium"
                    label={label}
                    className={className}
                >
                    <ImageEye />
                </Button>
            );
        }
    }
);



export const EditButton = ({ isShow, ...props }) => (
    <RaEditButton
        label={""}
        size="medium"
        {...{ ...props, ...(isShow ? { icon: <ImageEye /> } : {}) }}
    />
);





export const MediaButton = connect(
    null,
    {
        showNotification
        //  push
    }
)(
    class extends Component {

        state={
            images:false
        }

        render() {
            const { record,resource, alwaysShown, label, className } = this.props;


            const click=()=>{

                const traverse=(node)=>{

                    let collect=[]

                    for(const elem of node){
                        if(Array.isArray(elem)){
                            collect=[...collect,...traverse(elem)]
                        }else{
                            if(typeof elem==='object'&&elem!==null){
                                if(elem.uri){

                                    collect.push(elem.uri)
                                }else if(elem.image?.uri){
                                    collect.push(elem.image.uri)
                                }
                            }
                        }
                    }
                    return collect
                }


                const imgs=traverse(Object.values(record));



                this.setState({ images: imgs });




            }

            return (
                <>
                <Button
                    title={label?null:"View photos"}
                    onClick={click}
                    size="medium"
                    label={label}
                    className={className}
                >
                    <ImageImage />
                </Button>

                    {this.state.images?<Dialog
                        fullWidth
                        maxWidth="lg"

                        open={true}
                        onClose={()=>{
                            this.setState({images:false});
                        }}
                    >
                        <DialogTitle>Images</DialogTitle>
                        <DialogContent>

                            <div style={{
                                display:"grid",
                                gridTemplateColumns:"repeat(auto-fill, minmax(200px, 1fr))",
                                gap:"1rem"
                            }}>
                                {this.state.images.map((uri,i)=>{
                                    const url=fileUriToURl(uri);
                                    return (<a href={url} target="_blank" key={i.toString()} style={{
                                        position:"relative",
                                        paddingBottom:"61%",
                                        background:"#eee",
                                    }}>
                                        <img src={url} style={{
                                            position:"absolute",
                                            top:0,
                                            left:0,
                                            width:"100%",
                                            height:"100%",
                                            objectFit:"contain",
                                            objectPosition:"center"
                                        }}/>
                                    </a>)
                                })}
                            </div>

                            {/*
                               <Button
                                style={{ float: "right" }}
                                title={"Open image in new tab"}
                                href={src}
                                target="_blank"
                            >
                                <IconNewTab />
                            </Button>

                                <img
                                    alt="preview"
                                    className="transp-bg"
                                    src={src+'?w=1000&h=1000'}
                                    style={{
                                        maxWidth: "100%"
                                    }}
                                />

                            */}

                        </DialogContent>

                    </Dialog>:null}


                    </>
            );
        }
    }
);
