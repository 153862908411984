import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudGetList } from 'react-admin';
import TimelineLoaded from './TimelineLoaded';
import TimelineLoading from './TimelineLoading';

export class Timeline extends Component {
    page = 1;
    perPage=10;
    constructor(props) {
        super(props);
        this.state = {
            events: props.ids.map(id => props.data[id]),
            latestId: props.ids[props.ids.length - 1]
        };
    }

    updateData = (perPage) => {
        this.props.crudGetList(
            'timeline',
            { page: this.page, perPage: this.perPage },
            { field: 'id', order: 'DESC' },
            this.props.filter
        );
    };

    componentDidMount() {
        //alert(":mount")
        this.updateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.ids !== prevProps.ids) {
            const { ids, data } = this.props;
          
            const latestId = ids[ids.length - 1];
            if (latestId && latestId !== prevState.latestId) {
                const newEvents = ids.map(id => data[id]);
                this.setState(state => ({
                    events: state.events.concat(newEvents),
                    latestId,
                }));
            }
        }
    }
    componentWillUnmount() {
        this.page = 0;
        this.updateData();
    }

    handleLoadMore = () => {
        this.page = this.page + 1;
        this.updateData();
    };

    render() {
        const { events } = this.state;
        const { total, loadedOnce, loadDataIntoForm } = this.props;
        return (
            <React.Fragment>
                
                <div style={{maxWidth:800}}>
                {!loadedOnce ? (
                    <TimelineLoading />
                ) : events.length === 0 ? (
                    null
                ) : (
                    <TimelineLoaded
                        events={events}
                        total={total}
                        handleLoadMore={this.handleLoadMore}
                        loadDataIntoForm={loadDataIntoForm}
                    />
                )}
                </div>
            </React.Fragment>
        );
    }
}

Timeline.defaultProps = {
    ids: [],
    data: {},
    crudGetList: () => null,
};

const mapStateToProps = state => ({
    ids: state.admin.resources.timeline?.list.ids,
    data: state.admin.resources.timeline?.data,
    total: state.admin.resources.timeline?.list.total,
    loadedOnce: state.admin.resources.timeline?.list.loadedOnce,
});

export default connect(
    mapStateToProps,
    { crudGetList }
)(Timeline);