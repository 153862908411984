import React from "react";

import { page as schema } from "ev-schema";

import { Filter,  BooleanInput, Toolbar } from "react-admin";

import { Edit, Create } from "cms-toolbox/ReactAdminHoc";

import {
    SearchInput,
    ReferenceInput,
    AutocompleteInput
} from "cms-toolbox/inputHoc";

import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";

import List from "cms-toolbox/List";

import { FormTab } from "cms-toolbox";

import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";
import { ListBulkActions, ListActions } from "cms-toolbox/ListActions";

export const PageCreate = props => (
    <Create title={<PageTitle />} {...props}>
        <PageForm />
    </Create>
);


const PageForm = props => (
      <TabbedFormHorizontal {...props} redirect={"edit"} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit" contentClassName="form-tabs-vertical">
  
        <FieldSet
            schemaFragment={schema}
            tab="main"
            tabLabel="Main"
            entityId={props.id}
        />
        <FieldSet
            schemaFragment={schema}
            tab="details"
            tabLabel="Details"
            entityId={props.id}
        />
        <FieldSet
            schemaFragment={schema}
            tab="media"
            tabLabel="Media"
            entityId={props.id}
        />
        <FieldSet
            schemaFragment={schema}
            tab="catalog"
            tabLabel="Catalog"
            entityId={props.id}
        />
        <FieldSet
            schemaFragment={schema}
            tab="meta"
            tabLabel="Metadata"
            entityId={props.id}
        />
    </FormTab>
        {props?.record?.id ? <EntityRevisionHistory {...props} /> : null}
    </TabbedFormHorizontal>
);

export const PageEdit = props => (
    <Edit title={<PageTitle />} {...props}>
        <PageForm redirect={false} />
    </Edit>
);

const PageTitle = ({ record }) => {
    return (
        <span>
            {record.id
                ? record.title
                    ? `Page: ${record.title}`
                    : `Page ${record.id}`
                : `New Page`}
        </span>
    );
};

const PageFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn source="q" variant="outlined" />
        <BooleanInput alwaysOn source="full-search" variant="outlined" label="Full search" />
       

        <ReferenceInput
            filterToQuery={q => ({
                q,
                page_id: {
                    operator: "!=",
                    operand: ""
                }
            })}
            sort={{field:"length(path)"}}
            alwaysOn
            label="Url path"
            reference="path"
            source="path"
        >
            <AutocompleteInput optionText="path" />
        </ReferenceInput>
    </Filter>
);

export class PageList extends React.Component {
    render = () => {
        return (
            <List
                title="Pages"
                perPage={10}
                {...this.props}
                filters={<PageFilter />}
                //pagination={null}

                bulkActionButtons={<ListBulkActions />}
                actions={<ListActions />}
            >
                <ListContent
                    visitAction={true}
                    editAction={true}
                    {...this.props}
                    showFields={[
                        { source: "title", media: "small" },
                        { source: "published", media: "small" },
                        { source: "path" },

                        {
                            source: "showCatalog",
                            label: "Products",
                            media: "small"
                        },
                        { source: "addCategory", media: "medium" },
                        { source: "created", media: "medium",type:"date" }
                    ]}
                    schema={schema}
                />
            </List>
        );
    };
}

/*
export const PageGrid = props => (
    <SchemaBuilder {...props} type="grid" schema={Schema}/>
);*/
