import React from "react";

import {Edit} from "cms-toolbox/ReactAdminHoc"


import { FieldSet } from "cms-toolbox/FormContent";

import {references as schema} from "ev-schema"





import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";


export const ReferencesEdit = props => (
	<Edit title={"References page"} {...props} id={"references"}>


  <TabbedFormHorizontal {...props} redirect={false}  warnWhenUnsavedChanges={false} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit">
		<FieldSet
            schemaFragment={schema}
            
        />
			
				
		

		</FormTab>
         <EntityRevisionHistory {...props} />
         </TabbedFormHorizontal>
	</Edit>
);
