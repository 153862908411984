import React from "react";
import { useInput } from "ra-core";
import { SelectInput, SelectArrayInput } from "react-admin";




 const recur = (choices, parent, exclude, pad = "") => {
        let ret = [];
        for (const i in choices) {
            if (choices[i].parent === parent) {
                // choices[i].disabled = true;
                const disabled =
                    typeof exclude === "boolean" || exclude !== choices[i].id
                        ? exclude
                        : true;
                ret = [
                    ...ret,
                    {
                        ...choices[i],
                        disabled: disabled === true,
                        text: pad + choices[i].text
                    },
                    ...recur(choices, choices[i].id, disabled, pad + "- ")
                ];
            }
        }
        return ret;
    };

const TreeTreeSelect = props => {


    
   
    let parts=props.source.split(".")
    parts[parts.length-1]='root'

  const {
     input: { value },
  } = useInput({
    source:parts.join('.')
  });

    const { exclude, root, multiple, ...rest } = props;
    let { choices } = rest;
    let newChoices = [];

    let useRoot=root
    if(!useRoot){

  useRoot=value

    }


    if (useRoot) {
        newChoices = recur(
            choices.filter(c => c.root === useRoot),
            null,
            ~~exclude
        );
    }

    if (!newChoices.length) {
        return null;
    }

    if (multiple) {
        return <SelectArrayInput {...rest} choices={newChoices} />;
    }

    return <SelectInput {...rest} choices={newChoices} />;
};

export default TreeTreeSelect;
