import React from "react";
//import TabbedFormDefault from "./TabbedFormVertical";
import {TabbedForm as RATabbedForm} from "react-admin"

import {
	SimpleForm as SimpleFormDefault,
	FormTab as FormTabDefault
} from "react-admin";
/*
export class TabbedForm extends React.Component {
	render() {
		return <TabbedFormDefault submitOnEnter={false}  
		//warnWhenUnsavedChanges={true}
		 {...this.props}  />;
	}
}*/

export class TabbedFormHorizontal extends React.Component {
	render() {
		return <RATabbedForm submitOnEnter={false} 
		//warnWhenUnsavedChanges={true}
		 {...this.props}  />;
	}
}


export class SimpleForm extends React.Component {
	render() {
		return (
			<SimpleFormDefault
				submitOnEnter={false}
				 //warnWhenUnsavedChanges={true}
				{...this.props}
				action={"javascript: void(0)"}
				
			/>
		);
	}
}

export class FormTab extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h1 className="form-tab-title">{this.props.label}</h1>
				<FormTabDefault {...this.props} />
			</React.Fragment>
		);
	}
}
