import * as React from "react";
import { useState } from "react";
import {useSelector} from "react-redux"
import PropTypes from "prop-types";
import { useTranslate } from "ra-core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { MenuItem } from "@material-ui/core";
import { ReactComponent as PaperclipIcon } from "icons/paperclip.svg";
import { withRouter } from "react-router-dom";

var VisitHistory = function(props) {


    const visits = useSelector(({visit})=>visit)

    var _a = useState(null),
        anchorEl = _a[0],
        setAnchorEl = _a[1];
    var translate = useTranslate();
    var label = props.label;

    var open = Boolean(anchorEl);
    var handleMenu = function(event) {
        return setAnchorEl(event.currentTarget);
    };
    var handleClose = function() {
        return setAnchorEl(null);
    };

    if(!visits.length){return null}
    return React.createElement(
        "div",
        null,

 React.createElement(
            Tooltip,
            { title: 'Recently visited' },

        React.createElement(
                IconButton,
                {
                    "aria-label": label && translate(label, { _: label }),
                    "aria-owns": open ? "menu-appbar" : null,
                    "aria-haspopup": true,
                    color: "inherit",
                     onClick: handleMenu
                },
                <React.Fragment>
                    <PaperclipIcon className="MuiSvgIcon-root"/>
                    
                </React.Fragment>
            )
        ),
        React.createElement(
            Menu,
            {
                id: "menu-appbar",
                anchorEl: anchorEl,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                },
                getContentAnchorEl:null,

                open: open,
                onClose: handleClose,
              
            },
            visits.map((path,i) => {
               
                /*const { name, flag, tld } = countries[code];
*/

                const isActive=props.history.location.pathname==='/'+path

                return (
                    <MenuItem
                        component={"a"}
                        key={path+'_'+i}
                        href={'/#/'+path}
                        selected={isActive}
                        onClick={handleClose}
                        //target="_blank"
                    >
                        
                        {path}
                    </MenuItem>
                );
            })
        )
    );
};
VisitHistory.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,

    icon: PropTypes.node
};
VisitHistory.defaultProps = {
    label: "ra.auth.user_menu",
    icon: React.createElement(AccountCircle, null)
};
export default withRouter(VisitHistory);
