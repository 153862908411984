import React, { Component } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { ReactComponent as Pin } from "icons/pin.svg";
import {Link} from "react-router-dom"

const Marker = ({id,containerProps}) => <div className="list-map-marker" {...containerProps}>
<Link  to={`/customer/${id}`}>
  <Pin className="marker-pin"/>
  </Link>
</div>;

class ListMap extends Component {
  ref = React.createRef();

  state = {};

  componentDidUpdate() {
    const { ids } = this.props;

    const fingerprint = [...(ids || [])].sort().join("-");
    if (this.state.fingerprint !== fingerprint) {

      this.setState({ fingerprint }, this.setMapProps);
    }
  }

  setMapProps() {
    const { ids, data } = this.props;
    if (!ids || !data) {
      return;
    }
    const points = ids
      .map(id => {
        const obj = data[id];
        return obj.coords && !(isNaN(obj.coords.x)||isNaN(obj.coords.y)) && !(obj.coords.x===0&&obj.coords.y===90) && obj;
      })
      .filter(a => a);
    if (!points.length) {
      return;
    }

    let maxLat = points[0].coords.y;
    let minLat = maxLat;
    let maxLon = points[0].coords.x;
    let minLon = maxLon;
    for (const { coords } of points.slice(1)) {
      const { x, y } = coords;
      if (x < minLon) {
        minLon = x;
      } else if (x > maxLon) {
        maxLon = x;
      }
      if (y < minLat) {
        minLat = y;
      } else if (y > maxLat) {
        maxLat = y;
      }
    }

    const bounds = {
      nw: {
        lat: maxLat + 0.01,
        lng: minLon - 0.01
      },
      se: {
        lat: minLat - 0.01,
        lng: maxLon + 0.01
      }
    };

    const { current } = this.ref;

    const size = {
      width: current?.offsetWidth || 1000,
      height: current?.offsetHeight || 300
    };

    const { center, zoom } = fitBounds(bounds, size);

    this.setState({
      map: { center, zoom, points }
    });
  }

  render() {
    return (
      <div ref={this.ref} className="list-map-container">
        {this.renderMap()}
      </div>
    );
  }

  renderMap() {
    const { map, fingerprint } = this.state;
    if (!map) {
      return null;
    }
    const { center, zoom, points } = map;

    return (
      <GoogleMapReact
      key={fingerprint}
        bootstrapURLKeys={{ key: "AIzaSyCAkYR0ZYdHG09rgK5uQT1TNYsuIqxWUaM" }}
        defaultCenter={center}
        defaultZoom={Math.min(zoom,10)}>
      
        {points.map(({ 

          id, coords }) => {
          return (
            <Marker key={id.toString()} id={id} lat={coords.y} lng={coords.x} containerProps={{
              onMouseOver:()=>{this.props.onFocus(id)},
              onMouseOut:()=>{this.props.onBlur(id)}
            }} />
          );
        })}
      </GoogleMapReact>
    );
  }
}

export default ListMap;
