import React from "react";
import MuiAvatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    avatar: {
        background:"white"
    },
    normal:{

    },
    small:{
         width: 30,
          height: 30,
    }
};



const AvatarView = ({ seed,gender, classes,size="normal" }) => (
    <MuiAvatar
        className={classes.avatar+' '+classes[size]}
        variant="rounded"
        src={seed && `https://avatars.dicebear.com/4.4/api/${gender||"human"}/${encodeURIComponent(seed.replace(/\//g,'').trim())}.svg`}
    />
);

const Avatar = withStyles(styles)(AvatarView);

export default Avatar;
