import React from "react";
import { connect } from "react-redux";

import List from "cms-toolbox/List";
import { renderFunctions, moment } from "cms-config";
import { Avatar } from "cms-toolbox";

import { ListMap } from "cms-toolbox";
import { SimpleForm } from "cms-toolbox/FormHoc";
import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";
import { Typography } from "@material-ui/core";

import { customer as schema } from "ev-schema";

import { ListBulkActions } from "cms-toolbox/ListActions";

import {
    Filter,
    useGetOne,
    CRUD_GET_ONE,
    ArrayField,
    SingleFieldList
} from "react-admin";

import { withTheme } from "@material-ui/core/styles";
import { Route } from "react-router";

import { Show, TextField, FunctionField, SimpleShowLayout } from "react-admin";

import { Drawer, withStyles } from "@material-ui/core";
import { push } from "connected-react-router";

import { Helmet } from "react-helmet";

import {region} from "cms-config/domain"

const CustomerFilter = props => <Filter {...props} />;

const styles = {
    drawerContent: {
        width: 400
    }
};

export const CustomerList = connect()(
    withTheme(
        withStyles(styles)(
            class extends React.Component {
                state = {};

                onFocus = id => {
                    this.setState({ hover: id });
                };
                onBlur = () => {
                    this.setState({ hover: null });
                };

                handleClose = () => {
                    this.props.dispatch(push("/customer"));
                };

                render = () => {
                    const { theme, classes, dispatch, ...rest } = this.props;

                    return (
                        <React.Fragment>
                            {region==='advoc'?<iframe
                                src="/offerte-advocaathulp.html"
                                style={{
                                    background: "white",
                                    height: "1050px",
                                    border: "10px solid white"
                                }}
                            />:null}

                            <List
                                title={"Customers"}
                                {...rest}
                               // actions={null}
                                //bulkActionButtons={false}

                                bulkActionButtons={<ListBulkActions noDelete />}
                                perPage={10}
                                filters={<CustomerFilter />}
                                /*aside={
                                    <ListMap
                                        {...rest}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                    />
                                }*/
                                className="list-with-map"
                            >
                                <ListContent
                                    showAction
                                    {...rest}
                                    schema={schema}
                                    showFields={[
                                        {
                                            source: "created",
                                            media: "small",
                                            type: "date"
                                        },
                                        {
                                            source: "name",
                                            media: "small"
                                        },
                                        {
                                            source: "phone",
                                            media: "small"
                                        },
                                        {
                                            source: "email",
                                            media: "medium",
                                            type: "email"
                                        },
                                          {
                                            source: "type",
                                            media: "medium"
                                        }
                                    ]}
                                    rowStyle={({ id }) => {
                                        return id === this.state.hover
                                            ? {
                                                  background:
                                                      theme.palette.action.hover
                                              }
                                            : null;
                                    }}
                                />
                            </List>

                            <Route path="/customer/:id">
                                {({ match }) => {
                                    const isMatch =
                                        match &&
                                        match.params &&
                                        match.params.id;

                                    return (
                                        <Drawer
                                            open={isMatch || false}
                                            anchor="right"
                                            onClose={this.handleClose}
                                        >
                                            {isMatch ? (
                                                <CustomerShow
                                                    className={
                                                        classes.drawerContent
                                                    }
                                                    id={isMatch || null}
                                                    onCancel={this.handleClose}
                                                    {...rest}
                                                />
                                            ) : (
                                                <div
                                                    className={
                                                        classes.drawerContent
                                                    }
                                                />
                                            )}
                                        </Drawer>
                                    );
                                }}
                            </Route>
                        </React.Fragment>
                    );
                };
            }
        )
    )
);

const EmptyCont = ({ children }) => {
    return <div>{children}</div>;
};

export const CustomerShow = props => (
    <Show {...props} component={EmptyCont} title={"\u00A0"}>
        <ListContent isShowView schema={schema} />
    </Show>
);
