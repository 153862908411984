import { stringify } from "qs";
import merge from "deepmerge";
import axios from "axios";
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    CREATE_MANY,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
    GET_MANY,
    GET_MANY_REFERENCE,
    POST_TO_CUSTOM,
    POST_TO_AUTH,
    GET_RESOURCE,
    GET_CUSTOM
} from "./actions";

//import { HttpError } from 'react-admin';


import defaultSettings from "./default-settings";
import { NotImplementedError } from "./errors";
import init from "./initializer";

// Set HTTP interceptors.
init();

/**
 * Maps react-admin queries to a JSONAPI REST API
 *
 * @param {string} apiUrl the base URL for the JSONAPI
 * @param {Object} userSettings Settings to configure this client.
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (apiUrl, userSettings = {}) => (type, resource, params) => {
    let url = "";
    const settings = merge(defaultSettings, userSettings);

    const options = {
        headers: settings.headers
    };

    switch (type) {
        case POST_TO_AUTH:
        case POST_TO_CUSTOM: {
            url = `${apiUrl}/${resource}`;
            options.method = "POST";
            options.data = params;

            break;
        }

        case GET_LIST: {
            const { page, perPage } = params.pagination;

            if(page===0){
                return { data: [],total:0 }
            }

            // Create query with pagination params.
            const query = {
                //'page[number]': page,
                // 'page[size]': perPage,

                filter: JSON.stringify(params.filter || {}),
                range: JSON.stringify([
                    (page - 1) * perPage,
                    page * perPage - 1
                ])
            };

            // Add all filter params to query.
            /*Object.keys(params.filter || {}).forEach(key => {

                query[`filter[${key}]`] = params.filter[key];
            });*/

            // Add sort parameter
            if (params.sort && params.sort.field) {
                query.sort = JSON.stringify([
                    params.sort.field,
                    params.sort.order
                ]);
            }

            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }

        case GET_CUSTOM:
            url = `${apiUrl}/${resource}`;
            break;

        case GET_RESOURCE:
        case GET_ONE:
            url = `${apiUrl}/${resource}/${params.id}`;
            break;
        case "createMany":
        case CREATE_MANY:
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = "POST";
            options.data = JSON.stringify(params.data);
            break;

        case UPDATE: {
            url = `${apiUrl}/${resource}/${params.id}`;

            options.method = settings.updateMethod;
            options.data = JSON.stringify(params.data);
            break;
        }

        case UPDATE_MANY: {
            url = `${apiUrl}/${resource}/${params.ids.join(",")}`;
            options.method = settings.updateMethod;
            options.data = JSON.stringify(params.data);
            break;
        }

        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = "DELETE";
            break;
        case DELETE_MANY:
            url = `${apiUrl}/${resource}/${params.ids.join(",")}`;
            options.method = "DELETE";
            break;
        case GET_MANY: {
            const query = stringify(
                {
                    "filter[id]": params.ids
                },
                { arrayFormat: settings.arrayFormat }
            );

            url = `${apiUrl}/${resource}?${query}`;
            break;
        }

        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;

            // Create query with pagination params.
            const query = {
                "page[number]": page,
                "page[size]": perPage
            };

            // Add all filter params to query.
            Object.keys(params.filter || {}).forEach(key => {
                query[`filter[${key}]`] = params.filter[key];
            });

            // Add the reference id to the filter params.
            let refResource;
            const match = /_nested_(.*)_id/g.exec(params.target);
            if (match != null) {
                refResource = `${match[1]}/${params.id}/${resource}`;
            } else {
                query[`filter[${params.target}]`] = params.id;
                refResource = resource;
            }

            url = `${apiUrl}/${refResource}?${stringify(query)}`;
            break;
        }

        default:
            throw new NotImplementedError(
                `Unsupported Data Provider request type ${type}`
            );
    }

    return axios({ url, ...options })
        .then(response => {
            let total;
            window.redirectOverride=false;


            if(typeof response.data==='object'&&response.data.redirect){
                
                window.redirectOverride=response.data.redirect;
               
               
                return {data:{}}
            }

            // For all collection requests get the total count.
            if ([GET_LIST, GET_MANY, GET_MANY_REFERENCE].includes(type)) {
                try {
                    total = ~~response.headers["content-range"]
                        .split("/")
                        .pop();
                } catch (e) {}
            }

            switch (type) {
                case GET_MANY:
                case GET_LIST:
                case GET_MANY_REFERENCE: {
                    let currency = response.headers["x-currency"];
                    if (!currency || currency === "undefined") {
                        currency = null;
                    }
                    return {
                        data: response.data.map(row => ({ ...row, currency })),
                        total
                    };
                }

                case GET_CUSTOM:
                case GET_RESOURCE:
                case GET_ONE:
                case CREATE:
                case UPDATE: {
                    return {
                        data: response.data
                    };
                }
                case "createMany":
                case CREATE_MANY:
                case UPDATE_MANY: {
                    return { data: response.data };
                }

                /*case POST_TO_CUSTOM: {
                let ret = {};
                if (response.data.data) {
                    const { id, attributes } = response.data;

                    ret.data = {
                        id,
                        ...attributes
                    };
                }
                if (response.data.meta) {
                    ret.meta = response.data.meta;
                }
                return ret;
            }*/
                case DELETE_MANY: {
                    return {
                        data: { id: params.id }
                    };
                }
                case DELETE: {
                    return {
                        data: { id: params.id }
                    };
                }

                case POST_TO_AUTH: {
                    return {
                        data: response.data
                    };
                }

                default:
                    throw new NotImplementedError(
                        `Unsupported Data Provider request type ${type}`
                    );
            }
        })
       .catch(e => {
            
            const parts = (resource || "").split("/");
            if (parts[0] === "settings") {
                return {
                    data: {
                        id: parts[1]
                    }
                };
            }



             return Promise.reject(e);
            

        });
};
