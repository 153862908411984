import React from "react"


class DivNoProps extends React.Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}


export default DivNoProps