import React from "react";

import { Edit } from "cms-toolbox/ReactAdminHoc";

import { FieldSet } from "cms-toolbox/FormContent";

import { globals as schema } from "ev-schema";

import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory } from "cms-toolbox";

export const SettingsEdit = props => (
    <Edit title={"Global settings"} {...props} id={"globals"}>
        <TabbedFormHorizontal
            {...props}
            redirect={false}
            warnWhenUnsavedChanges={false}
        >
            <FormTab label="Edit" contentClassName="form-tabs-vertical">
                <FieldSet
                    schemaFragment={schema}
                    tab="general"
                    tabLabel="Theme elements"
                />
                <FieldSet
                    schemaFragment={schema}
                    tab="settings"
                    tabLabel="Constants"
                />
                <FieldSet
                    schemaFragment={schema}
                    tab="address"
                    tabLabel="Address"
                />
                <FieldSet
                    schemaFragment={schema}
                    tab="additional_services"
                    tabLabel="Add. services"
                    legend={[
                        {
                            image: {
                                src: "/manual/settings/affiliate-form.png",
                                width: 652,
                                height: 302
                            },
                            fields: [
                                "additional_service_description",
                                "additional_service_options"
                            ]
                        }
                    ]}
                />
                <FieldSet
                    schemaFragment={schema}
                    tab="locale"
                    tabLabel="Phrases"
                    legend={[
                        {
                            image: {
                                src: "/manual/settings/home-top.png",
                                width: 592,
                                height: 751
                            },
                            fields: [
                                "OPEN_MENU",
                                "TO_FRONT",
                                "PHONE_EXPL",
                                "FLT_AMT_1",
                                "FLT_AMT_MULT",
                                "FRONT_HEADER_SUB",
                                "Q_OCCASION",
                                "Q_BUDGET",
                                "Q_CATEGORY",
                                "SHOW_RESULTS",
                                "BTN_OFFER"
                            ]
                        },

                        {
                            image: {
                                src: "/manual/settings/corona.png",
                                width: 612,
                                height: 623
                            },
                            fields: [
                                "CORONABAR_SENTENCE",
                                "CORONA_RULE",
                                "CORONA_EXPL"
                            ]
                        },
                        {
                            image: {
                                src: "/manual/settings/product-settings.png",
                                width: 1095,
                                height: 700
                            },
                            fields: [
                                "TIMES_REQUESTED",
                                "RATING_TEXT",
                                "PRICE_ON_REQUEST",
                                "FAV",
                                "SUITABLE_FOR",
                                "CATEGORY",
                                "GENRES",
                                "CHECK_AV",
                                "BTN_NO_EXTRA",

                                "ORDER_DESC_DEFAULT",

                                "INP_NAME",
                                "INP_EMAIL",
                                "INP_PHONE"
                            ]
                        },
                        {
                            image: {
                                src: "/manual/settings/product-specs.png",
                                width: 794,
                                height: 392
                            },
                            fields: ["REPERTOIRE", "GENRES", "SONGS", "RELATED"]
                        },
                        {
                            image: {
                                src: "/manual/settings/order-form-extra.png",
                                width: 744,
                                height: 621
                            },
                            fields: [
                                "CLOSE",
                                "EXTRA_FORM",
                                "INP_DATE",
                                "INP_TIME_START",
                                "INP_TIME_END",
                                "INP_LOCATION",
                                "INP_WISHES",
                                "INP_GUESTS",
                                "INP_BUDGET",
                                "INP_PARTY",
                                "BTN_COMPLETE",
                                "BTN_NO_EXTRA"
                            ]
                        },

                        {
                            image: {
                                src: "/manual/settings/filter-bar.png",
                                width: 642,
                                height: 764
                            },
                            fields: [
                                "SEARCH_PLACEHOLDER",
                                "FLT_RESET",
                                "FLT_CATEGORY_TOP",
                                "BUDGET",
                                "FROM",
                                "TO",
                                "FLT_NO_MAX",
                                "OCCASION",

                                "RATING",
                                "FLT_RATING_MIN",
                                "GENRE"
                            ]
                        },
                        {
                            image: {
                                src: "/manual/settings/rating-total.png",
                                width: 640,
                                height: 77
                            },
                            fields: [
                                "RATING_SITE_TOTAL",
                                "RATING_SITE_AVG",
                                "RATING_AMT_MULT",
                                "RATING_AMT_1"
                            ]
                        },
                        {
                            image: {
                                src: "/manual/settings/product-list-top.png",
                                width: 749,
                                height: 297
                            },
                            fields: [
                                "READ_MORE",
                                "RECOMMENDATIONS",
                                "RECOMMENDATIONS_SKIP"
                            ]
                        },
                        {
                            image: {
                                src: "/manual/settings/product-list.png",
                                width: 658,
                                height: 508
                            },
                            fields: ["PERC_MATCH", "PERC_EXPL", "NEXT_AMT"]
                        },
                       /* {
                            image: {
                                src: "/manual/settings/help-popup.png",
                                width: 544,
                                height: 299
                            },
                            fields: [
                                "HELP_POPUP_TITLE",
                                "HELP_POPUP_TEXT",
                                "HELP_POPUP_YES",
                                "HELP_POPUP_NO"
                            ]
                        },*/
                        {
                            image: {
                                src: "/manual/settings/sort-input.png",
                                width: 195,
                                height: 177
                            },
                            fields: [
                                "SORT_RELEVANCE",
                                "SORT_RATING",
                                "SORT_PRICE_ASC",
                                "SORT_PRICE_DESC",
                                "SORT_TITLE",
                                "SORT_DATE"
                            ]
                        },
                        {
                            image: {
                                src: "/manual/settings/serp-advice.png",
                                width: 981,
                                height: 312
                            },
                            fields: [
                                "INFO_GENERAL",
                                "INFO_WE",
                                "INFO_ABOUT",
                                "ADVICE_HEAD",
                                "ADVICE_DESC",
                                "OR_CALL",
                                "CALL_ME",
                                "SEND"
                            ]
                        }
                    ]}
                />
                <FieldSet
                    schemaFragment={schema}
                    tab="mail"
                    tabLabel="E-mails"
                />
            </FormTab>
            <EntityRevisionHistory {...props} />
        </TabbedFormHorizontal>
    </Edit>
);
