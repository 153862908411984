import React from 'react';
import { Route } from 'react-router-dom';
//import Statistics from './Statistics';
import EditRedirect from './EditRedirect';
import Manual from "components/pages/Manual";

//import { FrontEdit } from "./Front";

export default [
    <Route path="/edit/:path+" component={EditRedirect} />,
    <Route path="/edit" component={EditRedirect} />,
    <Route path="/manual" component={Manual} />,
  //  <Route path="/page/front" component={Statistics} />,
  //  <Route  path="/page/quote-requested"    component={QuoteRequestedEdit} />,
];