import React, { useState } from "react";
import { useForm } from "react-final-form";

import { useDispatch } from "react-redux";
import { showNotification } from "react-admin";
//import { change, reset } from "redux-form";

import { useInput } from "ra-core";

import RemoveCircle from "@material-ui/icons/RemoveCircle";

import IconButton from "@material-ui/core/IconButton";

import { fileUriToURl } from "cms-toolbox/helpers/utils";
import { Typography } from "@material-ui/core";
import dataProvider from "cms-config/dataProvider";

import { FieldTitle } from "ra-core";
import { FormControl, InputLabel } from "@material-ui/core";

import { Progress } from ".";

const acceptString = accept => {
    if (!accept) {
        return "any file";
    }
    return accept
        .split(",")
        .map(s => {
            s = s.trim();
            const parts = s.split("/");
            if (parts[1] === "*") {
                return `any ${parts[0]}`;
            }
            return parts[1];
        })
        .join(", ");
};

export default props => {
    const dispatch = useDispatch();

    const { resource, source, label } = props;
    const { maxSize, accept } = props;
    const autoUpload = true;

    // import {fileUriToURl} from "$lib/utils"
    //export let value=null;
    //export let field={};

    const [newFilePending, setNewFilePending] = useState(false);
    const [progressPerc, setProgressPerc] = useState(false);

    // import {api} from "$lib/utils";

    const [localFilePreview, setLocalFilePreview] = useState(false);

    const { change } = useForm();

    const {
        input: { value },
        isRequired
    } = useInput(props);

    const remove = () => {
        change(
            props.source,

            null
        );
    };

    const onFileSelected = e => {
        e.preventDefault();
        e.stopPropagation();

        setLocalFilePreview(false);

        const file = e.target.files[0];

        if (file) {
            if (maxSize && file.size > maxSize) {
                e.target.value = "";
                change(
                    props.source,

                    null
                );

                alert(
                    `File is too big. Maximum size: ${(maxSize * 1e-6).toFixed(
                        2
                    )}MB`
                );
            } else {
                setNewFilePending(true);
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    setLocalFilePreview(e.target.result);
                };

                if (autoUpload) {
                    uploadToBucket(file);
                }
            }
        } else {
            change(
                props.source,

                null
            );
        }
    };

    const imageLoaded = () => {
        setProgressPerc(false);
        if (localFilePreview) {
            setTimeout(() => {
                setLocalFilePreview(false);
            }, 10);
        }
    };

    const imgClick = e => {
        e.stopPropagation();
        e.preventDefault();
        if (value) {
            const url = fileUriToURl(value);
            window.open(url);
        }
    };

    /////////////////////////////////

    const uploadToBucket = async file => {
        const { name, type } = file;

        const uploadHandle = await dataProvider("GET_ONE", "file-upload", {
            id: name
        });

        if (uploadHandle && uploadHandle.data) {
            const { url, fields, uri, fileId } = uploadHandle.data;

            const formData = new FormData();

            Object.entries({ ...fields, file }).forEach(([key, v]) => {
                formData.append(key, v);
            });

            const uploaded = await new Promise(function(resolve, reject) {
                setProgressPerc(0);

                const xhr = new XMLHttpRequest();

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            resolve(true);
                        } else {
                            setProgressPerc(false);
                            reject(false);
                        }
                    }
                };

                xhr.upload.onprogress = e => {
                    if (e.lengthComputable) {
                        setProgressPerc(
                            Math.min(100, (e.loaded / file.size) * 100)
                        );
                    }
                };

                xhr.open("POST", url);
                xhr.send(formData);
            });

            if (uploaded) {
                setNewFilePending(false);

                change(
                    props.source,

                    uri
                );
            }
        } else {
            dispatch(
                showNotification(
                    "The file you selected failed to upload.",
                    "error"
                )
            );
            change(props.source, null);
        }
    };

    return (
        <div className="image-bucket-uploader">
            <FormControl fullWidth={true}>
                {label !== "" && label !== false && (
                    <InputLabel shrink>
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    </InputLabel>
                )}

                <label className="file-label">
                    {value || newFilePending ? (
                        <span className="preview-container" onClick={imgClick}>
                            {value && !newFilePending ? (
                                <img
                                    alt="preview"
                                    src={fileUriToURl(value)}
                                    onLoad={imageLoaded}
                                    className="uploaded-img"
                                />
                            ) : null}

                            {localFilePreview ? (
                                <img
                                    className="preview-img"
                                    src={localFilePreview}
                                />
                            ) : null}

                            {typeof progressPerc === "number" ? (
                                <Progress percentage={progressPerc} />
                            ) : null}
                        </span>
                    ) : (
                        <div className="file-empty">
                            <Typography
                                variant="body"
                                className="diff-label-array dropzone-empty"
                            >
                                {
                                    "Drop a picture to upload, or click to select it."
                                }

                                <small style={{ display: "block" }}>
                                    Select {acceptString(accept)}
                                </small>
                            </Typography>
                        </div>
                    )}

                    <input
                        accept={accept}
                        className="file-input"
                        type="file"
                        onChange={e => onFileSelected(e)}
                    />

                    {value ? (
                        <IconButton onClick={() => remove()} title={"Delete"}>
                            <RemoveCircle />
                        </IconButton>
                    ) : null}
                </label>
            </FormControl>
        </div>
    );
};
