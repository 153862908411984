import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";

export default class ThumbnailImage extends Component {
    state = {};
    render() {
        const { src } = this.props;
        return (
            <React.Fragment>
                <img
                    {...this.props}
                    src={'https://media-tunnel-dot-ht-evenses.ew.r.appspot.com/w:70,h:38,f:inside,p:80/'+src}
                    onClick={e => {
                        e.stopPropagation();
                        this.setState({
                            open: true
                        });
                    }}
                    alt="thumbnail"
                />
                {this.state.open ? (
                    <Dialog
                        onClick={e => e.stopPropagation()}
                        open={true}
                        maxWidth={"xl"}
                        onClose={() => {
                            this.setState({ open: false });
                        }}
                    >
                        <img
                            style={{
                                height: "auto",
                                objectFit: "contain",
                                objectPosition: "top",
                                minWidth:100,
                                minHeight:100
                            }}
                            src={src}
                            alt="thumbnail"
                        />
                    </Dialog>
                ) : null}
            </React.Fragment>
        );
    }
}
