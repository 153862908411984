import React from "react";
import { withRouter } from "react-router-dom";

import MarkdownIt from "markdown-it";
import markdownItAnchor from "markdown-it-anchor";

const mdParser = new MarkdownIt({
	breaks: true,
	html: true
}).use(markdownItAnchor, {
	level: 1,
	slugify: s =>
		encodeURIComponent(
			String(s)
				.trim()
				.toLowerCase()
				.replace(/[^\w\s]/gi, "")
				.replace(/\s+/g, "-")
		),
	permalink: false
});

const Markdown = class extends React.Component {
		state = {};
		static getDerivedStateFromProps({ source, readmore }, state) {
			let html = source ? mdParser.render(source.trim()) : "";
			html = html.replace(
				/<(p|ol|ul)>/gi,
				(_, a) =>
					`<${a} class="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom">`
			);
			html = html.replace(
				/<h([1-6])([^>]*)>/gi,
				(_, a, b) =>
					`<h${a} class="MuiTypography-root MuiTypography-h${a} MuiTypography-gutterBottom" ${b}>`
			);

			return {
				html
			};
		}

		contentClickHandler = e => {
			if (e.ctrlKey) {
				return;
			}

			const targetLink = e.target.closest("a");
			if (!targetLink) {
				return;
			}

			if (targetLink.hash) {
				const scrolltoelem = document.getElementById(
					targetLink.hash.slice(1)
				);
				if (scrolltoelem) {
					scrolltoelem.scrollIntoView({
						behavior: "smooth",
						block: "start"
					});
					return e.preventDefault();
				}
			}

			const href = targetLink.pathname;
			const host = targetLink.host;
			if (
				!href ||
				(host !== window.location.host &&
					host !== this.props.baseHostName)
			) {
				window.open(targetLink.href);
				return e.preventDefault();
			}

			e.preventDefault();
			this.props.history.push(href + targetLink.search);
			//this.props.dispatch(push(href + targetLink.search));
		};

		render() {
			const { Component = "div" } = this.props;

			return (
				<Component
					onClick={this.contentClickHandler}
					className="markdown"
					dangerouslySetInnerHTML={{
						__html: this.state.html || ""
					}}
				/>
			);
		}
	}


export default withRouter(Markdown);
