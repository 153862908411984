import React from "react";
import { Filter, BooleanInput, FormTab } from "react-admin";

import { Edit, Create } from "cms-toolbox/ReactAdminHoc";

import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import List from "cms-toolbox/List";
import { EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";
import {
    SearchInput,
    ReferenceInput,
    AutocompleteInput
} from "cms-toolbox/inputHoc";

import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";
import { article as schema } from "ev-schema";

import { ListBulkActions, ListActions } from "cms-toolbox/ListActions";

const ArticleFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn source="q" />
        <BooleanInput
            alwaysOn
            source="full-search"
            variant="outlined"
            label="Full search"
        />
        <ReferenceInput
            filterToQuery={q => ({
                q,
                article_id: {
                    operator: "!=",
                    operand: ""
                }
            })}
            sort={{ field: "length(path)" }}
            alwaysOn
            label="Url path"
            reference="path"
            source="path"
        >
            <AutocompleteInput optionText="path" />
        </ReferenceInput>
    </Filter>
);

export class ArticleList extends React.Component {
    render = () => {
        return (
            <List
                title="Articles"
                {...this.props}
                perPage={10}
                filters={<ArticleFilter />}
                //pagination={null}
                bulkActionButtons={<ListBulkActions />}
                actions={<ListActions />}
            >
                <ListContent
                    visitAction={true}
                    editAction={true}
                    {...this.props}
                    showFields={[
                        {
                            source: "title",
                            media: "small"
                        },
                        {
                            source: "published"
                        },
                        {
                            source: "path",
                            media: "small"
                        },

                        {
                            source: "image"
                        },
                        { source: "created", media: "medium", type: "date" }
                    ]}
                    schema={schema}
                />
            </List>
        );
    };
}

const ArticleTitle = ({ record }) => {
    return (
        <span>
            {record.id
                ? record.title
                    ? record.title
                    : `Article ${record.id}`
                : `New Article`}
        </span>
    );
};

const ArticleForm = props => (
    <TabbedFormHorizontal {...props} redirect={"edit"} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit">
            <FieldSet schemaFragment={schema} entityId={props.id} />
        </FormTab>
        {props?.record?.id ? <EntityRevisionHistory {...props} /> : null}
    </TabbedFormHorizontal>
);

export const ArticleEdit = props => (
    <Edit
        title={<ArticleTitle />}
        {...props}
        //  aside={null/*<EntityRevisionHistory {...props}/>*/}
    >
        <ArticleForm redirect={false} />
    </Edit>
);

export const ArticleCreate = props => (
    <Create title={<ArticleTitle />} {...props}>
        <ArticleForm />
    </Create>
);
