
import * as React from 'react';
import { ReactComponent as Icon } from "./VideoEmbedIcon.svg";

const MarkDownInputEmbedPlugin = props => {
  
  const handleClick = () => {
    const rptext='INSERT_YOUTUBE_URL_HERE'
    props.editor.insertText(`\n@[youtube](${rptext})\n`,false,{
      end:12+rptext.length,
      start:12
    });
  }

  return (
    <span
      className="button"
      title="Insert Youtube video"
      onClick={handleClick}
    >
      <Icon className="rmel-iconfont" style={{
            height: '1.1em',
            width: '1.1em',
    verticalAlign: 'middle',
    marginBottom:'.1em'
      }}/>
    </span>
  );
}


MarkDownInputEmbedPlugin.align = 'left';
MarkDownInputEmbedPlugin.pluginName = 'embed';

export default MarkDownInputEmbedPlugin;