import React from "react";
import Login from "./LoginRaMaterialUi";
import LoginForm from "./LoginForm";
import { withStyles } from "@material-ui/core/styles";



/*const c1="#E8D287"
const c2="#F1DA82"
const c3="#E0C97F"

const w=90
const h=(w/4)*7*/
const styles = {
    login: {
        main: {
            backgroundColor: "#adadad",
            backgroundImage: "none !important"
            /* backgroundColor: c3,
            backgroundImage:
                `linear-gradient(30deg, ${c1} 12%, transparent 12.5%, transparent 87%, ${c1} 87.5%, ${c1}),`+
                ` linear-gradient(150deg, ${c1} 12%, transparent 12.5%, transparent 87%, ${c1} 87.5%, ${c1}),`+
                ` linear-gradient(30deg, ${c1} 12%, transparent 12.5%, transparent 87%, ${c1} 87.5%, ${c1}),`+
                ` linear-gradient(150deg, ${c1} 12%, transparent 12.5%, transparent 87%, ${c1} 87.5%, ${c1}),`+
                ` linear-gradient(60deg, ${c2} 25%, transparent 25.5%, transparent 75%, ${c2} 75%, ${c2}),`+
                ` linear-gradient(60deg, ${c2} 25%, transparent 25.5%, transparent 75%, ${c2} 75%, ${c2}) !important`,
            backgroundSize: `${w}px ${h}px`,
            backgroundPosition: `0 0, 0 0, ${w*0.5}px ${h*0.5}px, ${w*0.5}px ${h*0.5}px, 0 0, ${w*0.5}px ${h*0.5}px`,
            backgroundRepeat:"repeat" */
        },
        card: {
            padding: "5px 0 15px 0"
        }
    },
    form: {
        button: {
            height: "3em"
        }
    }
};

const MyLogin = props => (
    <Login {...props}>
        <LoginForm />
    
    </Login>
);

export default withStyles(styles.login)(MyLogin);
