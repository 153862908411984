import React, { useMemo } from "react";

import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import fastDiff from "fast-diff";

import { palette } from "cms-config";

const styles = {
	value: {
		wordBreak: "break-word"
	},
	removed: {
		color: palette.danger
	},
	added: {
		color: palette.success
	},
	ball: {
		width: 10,
		height: 10,
		borderRadius: "100%",
		display: "inline-block",
		verticalAlign: "middle",
		marginRight: 5
	},
	A: {
		background: palette.success
	},
	D: {
		background: palette.danger
	},
	E: {
		background: palette.warning
	}
};

const textify = obj => {
	if (obj === null) {
		return "";
	}
	if (typeof obj === "string") {
		return obj;
	}
	return JSON.stringify(obj);
};

const uncamelize = s => {
	if (typeof s !== "string" || !s.length) {
		return "";
	}
	return s
		.replace(/([A-Z])/g, " $1")
		.toLowerCase()
		.replace(/^./, function(str) {
			return str.toUpperCase();
		});
};

const _buildTreeFromDiff = (tree, { path, kind, item = {}, lhs, rhs }) => {
	try {
		if (~["id", "_relations", "currency"].indexOf(path[0])) {
			return tree;
		}
		let ref = tree;

		const last = path.length - 1;

		for (const [i, step] of path.entries()) {
			if (i === last) {
				switch (kind) {
					case "N":
					case "A": {
						if (!ref[step]) {
							ref[step] = [];
						}

						const add = {};

						const __KIND__ = item.kind === "D" ? "D" : "A";
						const o =
							item.kind === "D" ? item.lhs : rhs || item.rhs;
						const nr = __KIND__ === "D" ? -1 : 1;

						for (const r of Array.isArray(o) ? o : [o]) {
							if (typeof r === "object" && r !== null) {
								for (const i in r) {
									add[i] = {
										__DIFF__: [[nr, textify(r[i])]],
										__KIND__
									};
								}
								ref[step].push(add);
							} else {
								ref[step] = {
									__DIFF__: [[nr, textify(r)]],
									__KIND__
								};
								add = [
									{
										__DIFF__: [[nr, textify(r)]],
										__KIND__
									}
								];
							}
						}

						break;
					}
					case "E": {
						const l = item.lhs || lhs;
						const r = item.rhs || rhs;

						const lstring = typeof l === "string";
						const rstring = typeof r === "string";

						const textPrevious = (lstring ? l : textify(l)) || "";
						const textNew = (rstring ? r : textify(r)) || "";

						const shown = {
							__DIFF__:
								lstring && rstring
									? fastDiff(textPrevious, textNew)
									: [[-1, textPrevious], [1, textNew]],
							__KIND__: "E"
						};

						ref[step] = shown;
						break;
					}
				}
				break;
			}

			if (!ref[step]) {
				ref[step] = typeof path[i + 1] === "number" ? [] : {};
			}
			ref = ref[step];
		}
	} catch (e) {}
	return tree;
};

const buildTreeFromDiff = changes => {
	let tree = {};
	if (Array.isArray(changes)) {
		for (const change of changes) {
			tree = _buildTreeFromDiff(tree, change);
		}
	}
	return tree;
};

const DiffTable = ({ diff, classes, schema = [] }) => {
	const tree = useMemo(() => {
		if (!Array.isArray(diff)) {
			return false;
		}

		return buildTreeFromDiff(diff);
	}, [diff]);
	if (!tree) {
		return null;
	}

	const renderNode = (node, tail, schemaFragment) => {
		if (Array.isArray(node)) {
			return node.map((key, i) => {
				const newTail = tail + i;

				return (
					<li className="diff-level" key={newTail}>
						<Typography
							variant="body2"
							className="diff-label-array"
						>
							{"•"}
						</Typography>
						<ul>{renderNode(node[i], newTail, schemaFragment)}</ul>
					</li>
				);
			});
		}
		if (typeof node === "object" && node !== null) {
			const keys = Object.keys(node);
			return keys
				.map((key, i) => {
					if (key === "__KIND__") {
						return null;
					}
					if (key === "__DIFF__") {
						return (
							<li key={tail + "val"} className={classes.value}>
								<Typography variant="body2">
									<span
										className={
											classes.ball +
											" " +
											classes[node.__KIND__]
										}
									/>
									
									{node[key].map(([type, text], i) => {
										const key = i.toString();
										switch (type) {
											case -1: {
												return (
													<s
														key={key}
														className={
															classes.removed
														}
													>
														{text}
													</s>
												);
											}
											case 1: {
												return (
													<span
														key={key}
														className={
															classes.added
														}
													>
														{text}
													</span>
												);
											}
											default: {
												return (
													<span key={key}>
														{text}
													</span>
												);
											}
										}
									})}
								</Typography>
							</li>
						);
					}

					const newTail = tail + key;

					const fieldSchema = schemaFragment.find(
						({ source }) => source === key
					);

					return (
						<li className="diff-level" key={newTail}>
							<Typography variant="body2" className="diff-label">
								{(fieldSchema && fieldSchema.label) ||
									uncamelize(key)}
								:
							</Typography>
							<ul>
								{renderNode(
									node[key],
									newTail,
									fieldSchema && fieldSchema.sub
										? fieldSchema.sub
										: []
								)}
							</ul>
						</li>
					);
				})
				.filter(a => a);
		}

		return null;
	};

	return <ul className="diff-table">{renderNode(tree, "", schema)}</ul>;
};

export default withStyles(styles)(DiffTable);
