import React from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import dataProvider from "cms-config/dataProvider.js";

const EditRedirect = connect()(
	class extends React.Component {
		state = { path: false };
		componentDidMount() {
			this.componentDidUpdate();
		}
		componentDidUpdate() {
			const { match } = this.props;
			const { params = {} } = match;
			const { path = "" } = params;

			if (path !== this.state.path) {
				this.setState({ path }, () => {
					if (!path) {
						this.props.history.push("/settings/front");
					} else {
						dataProvider("GET_LIST", "path", {
							filter: {
								path
							},
							pagination: {
								page: 1,
								perPage: 1
							}
						}).then(({ data }) => {
							
								const row = data && data.length ? data[0]:{};
								let redirect;
								if (row.page_id) {
									redirect = `/page/${row.page_id}`;
								} else if (row.article_id) {
									redirect = `/article/${row.article_id}`;
								} else if (row.product_id) {
									redirect = `/product/${row.product_id}`;
								} else if (row.view) {
									redirect = `/settings/${row.view}`;
								}else{
									redirect='/settings/notfound'
								}
								if (redirect) {
									this.props.history.push(redirect);
								}
							
						});
					}
				});
			}
		}

		render() {
			const { path = "" } = this.state;

			let text = `Redirecting you to /${path}...`;

			return (
				<Card>
					<Title title="Redirecting to edit page" />
					<CardContent>{text}</CardContent>
				</Card>
			);
		}
	}
);

export default EditRedirect;
