import React, {
    useCallback,
    useEffect,
    useRef,

    useMemo,
    isValidElement,
} from 'react';
import Downshift from 'downshift';
import classNames from 'classnames';
import get from 'lodash/get';
import { TextField, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    useInput,
    FieldTitle,

    useSuggestions,
    warning,
} from 'ra-core';

import InputHelperText from 'ra-ui-materialui/lib/input/InputHelperText';
import AutocompleteSuggestionList from 'ra-ui-materialui/lib/input/AutocompleteSuggestionList';
import AutocompleteSuggestionItem from 'ra-ui-materialui/lib/input/AutocompleteSuggestionItem';



import {
    SortableContainer,
    SortableElement,
    sortableHandle
} from "react-sortable-hoc";



 const DragHandle = sortableHandle(({label}) => (
        <span style={{cursor:"move"}}>{label}</span>
    ));

const SortableItem = SortableElement(
    ({ children, index }) =>
        children
);

const SortableList = SortableContainer(({ items, className }) => {
    return (
        <div className={className}>
           {items}
        </div>
    );
});


const arrayMoveMutate = (array, from, to) => {
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = to < 0 ? array.length + to : to;

        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
};

const arrayMove = (array, from, to) => {
    array = [...array];
    arrayMoveMutate(array, from, to);
    return array;
};



const AutocompleteArrayInput = props => {
    const {
        allowDuplicates,
        allowEmpty,
        classes: classesOverride,
        choices = [],
        emptyText,
        emptyValue,
        format,
        fullWidth,
        helperText,
        id: idOverride,
        input: inputOverride,
        isRequired: isRequiredOverride,
        label,
        limitChoicesToValue,
        margin = 'dense',
        matchSuggestion,
        meta: metaOverride,
        onBlur,
        onChange,
        onFocus,
        options: {
            suggestionsContainerProps,
            labelProps,
            InputProps,
            ...options
        } = {},
        optionText = 'name',
        optionValue = 'id',
        parse,
        resource,
        setFilter,
        shouldRenderSuggestions: shouldRenderSuggestionsOverride,
        source,
        suggestionLimit,
        translateChoice = true,
        validate,
        variant = 'filled',
        ...rest
    } = props;
    warning(
        isValidElement(optionText) && !matchSuggestion,
        `If the optionText prop is a React element, you must also specify the matchSuggestion prop:
<AutocompleteArrayInput
    matchSuggestion={(filterValue, suggestion) => true}
/>
        `
    );

    warning(
        source === undefined,
        `If you're not wrapping the AutocompleteArrayInput inside a ReferenceArrayInput, you must provide the source prop`
    );

    warning(
        choices === undefined,
        `If you're not wrapping the AutocompleteArrayInput inside a ReferenceArrayInput, you must provide the choices prop`
    );

    const classes = useStyles(props);

    let inputEl = useRef();
    let anchorEl = useRef();

    const {
        id,
        input,
        isRequired,
        meta: { touched, error },
    } = useInput({
        format,
        id: idOverride,
        input: inputOverride,
        meta: metaOverride,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const values = input.value || [];

    const [filterValue, setFilterValue] = React.useState('');

    const getSuggestionFromValue = useCallback(
        value => choices.find(choice => get(choice, optionValue) === value),
        [choices, optionValue]
    );

    const selectedItems = useMemo(() => values.map(getSuggestionFromValue), [
        getSuggestionFromValue,
        values,
    ]);

      const sortContRef = useRef(null);

    const { getChoiceText, getChoiceValue, getSuggestions } = useSuggestions({
        allowDuplicates,
        allowEmpty,
        choices,
        emptyText,
        emptyValue,
        limitChoicesToValue,
        matchSuggestion,
        optionText,
        optionValue,
        selectedItem: selectedItems,
        suggestionLimit,
        translateChoice,
    });

    const handleFilterChange = useCallback(
        (eventOrValue) => {
            const event = eventOrValue;
            const value = event.target
                ? event.target.value
                : (eventOrValue);

            setFilterValue(value);
            if (setFilter) {
                setFilter(value);
            }
        },
        [setFilter, setFilterValue]
    );

    // We must reset the filter every time the value changes to ensure we
    // display at least some choices even if the input has a value.
    // Otherwise, it would only display the currently selected one and the user
    // would have to first clear the input before seeing any other choices
    useEffect(() => {
        handleFilterChange('');
    }, [values.join(','), handleFilterChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReorder = useCallback(
 
 ({ oldIndex, newIndex }) => {
            const newSelectedItems =  arrayMove(selectedItems,oldIndex,newIndex);
           
            input.onChange(newSelectedItems.map(getChoiceValue));
        },
        [input, selectedItems, getChoiceValue]

    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            // Remove latest item from array when user hits backspace with no text
            if (
                selectedItems.length &&
                !filterValue.length &&
                event.key === 'Backspace'
            ) {
                const newSelectedItems = selectedItems.slice(
                    0,
                    selectedItems.length - 1
                );
                input.onChange(newSelectedItems.map(getChoiceValue));
            }
        },
        [filterValue.length, getChoiceValue, input, selectedItems]
    );

    const handleChange = useCallback(
        (item: any) => {
            let newSelectedItems =
                !allowDuplicates && selectedItems.includes(item)
                    ? [...selectedItems]
                    : [...selectedItems, item];
            setFilterValue('');
            input.onChange(newSelectedItems.map(getChoiceValue));
        },
        [allowDuplicates, getChoiceValue, input, selectedItems, setFilterValue]
    );

    const handleDelete = useCallback(
        item => () => {
            const newSelectedItems = [...selectedItems];
            newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
            input.onChange(newSelectedItems.map(getChoiceValue));
        },
        [input, selectedItems, getChoiceValue]
    );

    // This function ensures that the suggestion list stay aligned to the
    // input element even if it moves (because user scrolled for example)
    const updateAnchorEl = () => {
        if (!inputEl.current) {
            return;
        }

        const inputPosition = inputEl.current.getBoundingClientRect();

        // It works by implementing a mock element providing the only method used
        // by the PopOver component, getBoundingClientRect, which will return a
        // position based on the input position
        if (!anchorEl.current) {
            anchorEl.current = { getBoundingClientRect: () => inputPosition };
        } else {
            const anchorPosition = anchorEl.current.getBoundingClientRect();

            if (
                anchorPosition.x !== inputPosition.x ||
                anchorPosition.y !== inputPosition.y
            ) {
                anchorEl.current = {
                    getBoundingClientRect: () => inputPosition,
                };
            }
        }
    };

    const storeInputRef = input => {
        inputEl.current = input;
        updateAnchorEl();
    };

    const handleBlur = useCallback(
        event => {
            setFilterValue('');
            handleFilterChange('');
            input.onBlur(event);
        },
        [handleFilterChange, input, setFilterValue]
    );

    const handleFocus = useCallback(
        openMenu => event => {
            openMenu(event);
            input.onFocus(event);
        },
        [input]
    );

    const handleClick = useCallback(
        openMenu => event => {
            if (event.target === inputEl.current) {
                openMenu(event);
            }
        },
        []
    );

    const shouldRenderSuggestions = val => {
        if (
            shouldRenderSuggestionsOverride !== undefined &&
            typeof shouldRenderSuggestionsOverride === 'function'
        ) {
            return shouldRenderSuggestionsOverride(val);
        }

        return true;
    };

    return (
        <Downshift
            inputValue={filterValue}
            onChange={handleChange}
            selectedItem={selectedItems}
            itemToString={item => getChoiceValue(item)}
            {...rest}
        >
            {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue: suggestionFilter,
                highlightedIndex,
                openMenu,
            }) => {
                const isMenuOpen =
                    isOpen && shouldRenderSuggestions(suggestionFilter);
                const {
                    id: idFromDownshift,
                    onBlur,
                    onChange,
                    onFocus,
                    ref,
                    color,
                    size,
                    ...inputProps
                } = getInputProps({
                    onBlur: handleBlur,
                    onFocus: handleFocus(openMenu),
                    onClick: handleClick(openMenu),
                    onKeyDown: handleKeyDown,
                });

                return (
                    <div className={classes.container}>
                     <div className="sort-ghost-cont" ref={sortContRef} />
                        <TextField
                            id={id}
                            fullWidth={fullWidth}
                            InputProps={{
                                inputRef: storeInputRef,
                                classes: {
                                    root: classNames(classes.inputRoot, {
                                        [classes.inputRootFilled]:
                                            variant === 'filled',
                                    }),
                                    input: classes.inputInput,
                                },
                                startAdornment: (
                                    <SortableList
                                        className={classNames({
                                            [classes.chipContainerFilled]:
                                                variant === 'filled',
                                        })}
useDragHandle={true}
                helperContainer={sortContRef.current}
               axis={'xy'}
                onSortEnd={handleReorder}
                items={selectedItems.map((item, index) => (
                                            <SortableItem index={index} key={index.toString()}><Chip
                                                
                                                tabIndex={-1}
                                                label={<DragHandle label={getChoiceText(item)} />}
                                        
                                                onDelete={handleDelete(item)}
                                                {...(index===0?{
                                                    title:"Main path",
                                                   color:"primary",
                                                   className:classes.chipMargin
                                                }:{className:classes.chip+' '+classes.chipMargin})}
                                            /></SortableItem>
                                        ))}
                                    />
                                ),
                                onBlur,
                                onChange: event => {
                                    handleFilterChange(event);
                                    onChange(
                                        event
                                    );
                                },
                                onFocus,
                            }}
                            error={!!(touched && error)}
                            label={
                                <FieldTitle
                                    label={label}
                                    {...labelProps}
                                    source={source}
                                    resource={resource}
                                    isRequired={
                                        typeof isRequiredOverride !==
                                        'undefined'
                                            ? isRequiredOverride
                                            : isRequired
                                    }
                                />
                            }
                            InputLabelProps={getLabelProps({
                                htmlFor: id,
                            })}
                            helperText={
                                <InputHelperText
                                    touched={touched}
                                    error={error}
                                    helperText={helperText}
                                />
                            }
                            variant={variant}
                            margin={margin}
                            color={color}
                            size={size}
                            {...inputProps}
                            {...options}
                        />
                        <AutocompleteSuggestionList
                            isOpen={isMenuOpen}
                            menuProps={getMenuProps(
                                {},
                                // https://github.com/downshift-js/downshift/issues/235
                                { suppressRefError: true }
                            )}
                            inputEl={inputEl.current}
                            suggestionsContainerProps={
                                suggestionsContainerProps
                            }
                        >
                            {getSuggestions(suggestionFilter).map(
                                (suggestion, index) => (
                                    <AutocompleteSuggestionItem
                                        key={getChoiceValue(suggestion)}
                                        suggestion={suggestion}
                                        index={index}
                                        highlightedIndex={highlightedIndex}
                                        isSelected={selectedItems
                                            .map(getChoiceValue)
                                            .includes(
                                                getChoiceValue(suggestion)
                                            )}
                                        filterValue={filterValue}
                                        getSuggestionText={getChoiceText}
                                        {...getItemProps({
                                            item: suggestion,
                                        })}
                                    />
                                )
                            )}
                        </AutocompleteSuggestionList>
                    </div>
                );
            }}
        </Downshift>
    );
};

const useStyles = makeStyles(
    theme => {
        const chipBackgroundColor =
            theme.palette.type === 'light'
                ? 'rgba(0, 0, 0, 0.09)'
                : 'rgba(255, 255, 255, 0.09)';

        return {
            root: {
                flexGrow: 1,
                height: 250,
            },
            container: {
                flexGrow: 1,
                position: 'relative',
            },
            paper: {
                position: 'absolute',
                zIndex: 1,
                marginTop: theme.spacing(1),
                left: 0,
                right: 0,
            },
            chip:{

            },
            chipMargin: {
                margin: theme.spacing(0.5, 0.5, 0.5, 0),
            },
            chipContainerFilled: {
                margin: '27px 12px 10px 0',
            },
            inputRoot: {
                flexWrap: 'wrap',
            },
            inputRootFilled: {
                flexWrap: 'wrap',
                '& $chip': {
                    backgroundColor: chipBackgroundColor,
                },
            },
            inputInput: {
                width: 'auto',
                flexGrow: 1,
            },
            divider: {
                height: theme.spacing(2),
            },
        };
    },
    { name: 'RaAutocompleteArrayInput' }
);

export default AutocompleteArrayInput;
