import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

import Markdown from "cms-toolbox/Markdown";

import manual from "./manual.md";

import { getPermissions } from "cms-config/authProvider";


const Manual = () => {
    const [md, setMd] = useState("");

    useEffect(() => {
        async function fetchData() {
            fetch(manual)
                .then(res => res.text())
                .then(text => setMd(text));
        }
        fetchData();
    },[]);

    return (
        <Card>
            <Card>
                <Card>
                    <CardContent>
                        <Markdown source={md} />
                    </CardContent>
                </Card>
            </Card>
        </Card>
    );
};

export default Manual;
