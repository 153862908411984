import React from 'react'
import {
  TextInput as RaTextInput,
  NumberInput as RaNumberInput,
  SelectInput as RaSelectInput,
  DateInput as RaDateInput,
  DateTimeInput as RaDateTimeInput,
  NullableBooleanInput as RaNullableBooleanInput,
  AutocompleteInput as RaAutocompleteInput,
  ReferenceInput as RaReferenceInput,
  SearchInput as RaSearchInput,
  AutocompleteArrayInput as RaAutocompleteArrayInput,
  ReferenceArrayInput as RaReferenceArrayInput,
} from 'react-admin'

const standardize = Component => props => <Component {...props} variant="outlined"  />

export const TextInput = standardize(RaTextInput)
export const NumberInput = standardize(RaNumberInput)
export const SelectInput = standardize(RaSelectInput)
export const DateInput = standardize(RaDateInput)
export const DateTimeInput = standardize(RaDateTimeInput)
export const NullableBooleanInput = standardize(RaNullableBooleanInput)
export const AutocompleteInput = standardize(RaAutocompleteInput)
export const ReferenceInput = standardize(RaReferenceInput)
export const SearchInput = standardize(RaSearchInput)
export const AutocompleteArrayInput = standardize(RaAutocompleteArrayInput)
export const ReferenceArrayInput = standardize(RaReferenceArrayInput)
