import React from "react";

import { Toolbar, SaveButton, DeleteButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { VisitButton } from "./Buttons";

import { palette } from "cms-config";

import ThumbUp from "@material-ui/icons/ThumbUp";
import ThumbDown from "@material-ui/icons/ThumbDown";

const valueOrDefault = (value, defaultValue) =>
	typeof value === "undefined" ? defaultValue : value;

const useStyles = makeStyles(theme => ({
	defaultToolbar: {
		flex: 1,
		display: "flex",
		justifyContent: "space-between"
	},
	pad:{
		flex:1
	},
	mLeft:{
		marginLeft:15
	}
}));

const FormButtonsBottom = props => {
	const {
		alwaysEnableSaveButton,
		basePath,
		children,
		className,
		classes: classesOverride,
		handleSubmit,
		handleSubmitWithRedirect,
		invalid,
		pristine,
		record,
		redirect,
		resource,
		saving,
		submitOnEnter,
		undoable,
		width,
		visitOnWebsite,
		approveRejectMode,
		noDelete,
		noSave
	} = props;
	const classes = useStyles(props);
	const disabled = !valueOrDefault(alwaysEnableSaveButton, !pristine);

	return (
		<Toolbar {...props}>
			<div className={classes.defaultToolbar}>



{approveRejectMode?<SaveButton


handleSubmitWithRedirect={
						handleSubmitWithRedirect || handleSubmit
					}

            size="medium"
            style={{
                color: palette.success
            }}
            label="Approve"
            icon={<ThumbUp />}
            variant="text"
        />:null}
        {approveRejectMode?<DeleteButton

basePath={basePath}
						record={record}
						resource={resource}
						undoable={undoable}

            size="medium"
            style={{
                color: palette.danger
            }}
            label="Reject"
            icon={<ThumbDown />}
        />:(!noSave&&<SaveButton
					handleSubmitWithRedirect={
						handleSubmitWithRedirect || handleSubmit
					}
					disabled={disabled}
					invalid={invalid}
					redirect={redirect}
					saving={saving}
					submitOnEnter={submitOnEnter}
				/>)}

				
				{visitOnWebsite?<VisitButton record={record} resource={resource} alwaysShown label={"View on website"} className={classes.mLeft} />:null}

<div className={classes.pad}></div>
				{approveRejectMode?null:(record && typeof record.id !== "undefined" && !noDelete && (
					<DeleteButton
						basePath={basePath}
						record={record}
						resource={resource}
						undoable={undoable}
					/>
				))}
			</div>
		</Toolbar>
	);
};

export default FormButtonsBottom;
