import React from "react";

import { List } from "react-admin";

import Card from "@material-ui/core/Card";

class ListOverridden extends React.Component {
	render() {
		return (
			<Card className="list-card">
				<List perPage={10} sort={{ field: 'id', order: 'DESC' }} {...this.props} />
			</Card>
		);
	}
}

export default ListOverridden;
