import React from "react";
import { useSelector } from "react-redux";
import Badge from "@material-ui/core/Badge";
import { dataProvider } from "cms-config";

const ConceptsBadge = props => {
	const updateCount = useSelector(state => state.revisionAmount.updateCount);


	const [amount, setAmount] = React.useState(0);
	React.useEffect(() => {
		dataProvider("GET_CUSTOM", "revision-total", {}).then(({ data }) => {
			if (data && typeof data.amount !== "undefined") {
				setAmount(data.amount);
			}
		});
	}, [updateCount]); //

	return <Badge badgeContent={amount} color="primary" {...props} />;
};

export default ConceptsBadge;
