import React from "react";

export default props => {
	const { size = 70, strokeWidth = 5, percentage = 0 } = props;
	const radius = size * 0.5;
	const dashArray = Math.PI * (size - strokeWidth);

	return (
		<span style={{ "--dim": `${size}px` }} className="progress dim">
			<span className="progress-value">
				{Math.round(percentage)}
				<small>%</small>
			</span>
			<svg
				style={{ "stroke-width": `${strokeWidth}px` }}
				className="progress-svg dim"
				viewBox={`0 0 ${size} ${size}`}
				fill="none"
				strokeLinecap="round"
			>
				<circle
					className="progress-bg"
					cx={radius}
					cy={radius}
					r={radius - strokeWidth * 0.5}
				/>
				<circle
					className="progress-fill"
					cx={radius}
					cy={radius}
					r={radius - strokeWidth * 0.5}
					transform={`rotate(-90 ${radius} ${radius})`}
					strokeDashoffset={`${((100 - percentage) * dashArray) /
						100}`}
					strokeDasharray={dashArray}
				/>
			</svg>
		</span>
	);
};
