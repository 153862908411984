import React from "react";
import { connect } from "react-redux";

import { Edit, Create } from "cms-toolbox/ReactAdminHoc";

import { Filter } from "react-admin";

import { SimpleForm } from "cms-toolbox/FormHoc";

import { TextInput, SelectInput } from "cms-toolbox/inputHoc";

import { DisconnectedList } from "cms-toolbox/list/DisconnectedList";
import { DivNoProps } from "cms-toolbox";

import {  FormButtonsBottom } from "cms-toolbox";
import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";

import { Button } from "@material-ui/core";

import { getPermissions } from "cms-config/authProvider";


import { tree as schema, structure } from "ev-schema";

const TreeFilter = connect(({ tree }, { resource }) => ({
  search: tree.search || {}
}))(({ count, search, dispatch, ...props }) => {
  const { resource } = props;
  const { searchFocusIndex, matches = [] } = search;

  const searchFoundCount = matches.length;

  const selectPrevMatch = () =>
    dispatch({
      type: "TREE_SEARCH_SET",
      resource,
      payload: {
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1
      }
    });

  const selectNextMatch = () =>
    dispatch({
      type: "TREE_SEARCH_SET",
      resource,
      payload: {
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0
      }
    });

  return (
    <Filter {...props}>
      <SelectInput
        alwaysOn
        source="root"
        label="Tree"
        allowEmpty={false}
        choices={structure.menus}
      />
      <TextInput alwaysOn source="q" label="Search" />

      <DivNoProps source="extra" alwaysOn>
        <Button
          size="small"
          color="primary"
          disabled={!searchFoundCount}
          onClick={selectPrevMatch}
          //onClick={() => showFilter("main")}
          //startIcon={<ContentFilter />}
        >
          &lt;
        </Button>

        <Button disabled={!searchFoundCount} onClick={selectNextMatch}>
          &gt;
        </Button>

        <span>
          &nbsp;
          {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
          &nbsp;/&nbsp;
          {searchFoundCount || 0}
        </span>
      </DivNoProps>
    </Filter>
  );
});

export class TreeList extends React.Component {
  render = () => {
    return (
      <DisconnectedList
        filters={<TreeFilter />}
        filterDefaultValues={{ root: "category" }}
        title="Tree"
        {...this.props}
        pagination={null}
      >
        <ListContent {...this.props} schema={schema} isTree />
      </DisconnectedList>
    );
  };
}

const TreeTitle = ({ record }) => {
  return (
    <span>{record.text ? `Tree item ${record.text}` : `New tree item`}</span>
  );
};

const editSchema = JSON.parse(JSON.stringify(schema)).map(col => {
  if (col.source === "root") {
    col.props = {
      ...col.props,
      disabled: true
    };
  }
  return col;
});

export const TreeEdit = props => {
 const { role } = getPermissions();
  return (
  <Edit title={<TreeTitle />} {...props}>
    <SimpleForm toolbar={<FormButtonsBottom noDelete={role!=='admin'}/>} redirect={false}>
      <FieldSet schemaFragment={editSchema} entityId={props.id} />
    </SimpleForm>
  </Edit>
)};


export const TreeCreate = props => {


    return (

        <Create title={<TreeTitle />} {...props}>
            <SimpleForm>
                <FieldSet
                    schemaFragment={schema}
                    entityId={props.id}
                />
            </SimpleForm>
        </Create>
    );
}
