import React, { useCallback } from "react";

import { useForm } from "react-final-form";

import { Timeline } from "cms-toolbox";

import { revertChange } from "deep-diff";

import { withRouter } from "react-router-dom";

//import {useSelector} from "react-redux"

import { Link, useLocation } from "react-router-dom";
import MuiTab from "@material-ui/core/Tab";
import classnames from "classnames";

var __assign =
	(this && this.__assign) ||
	function() {
		__assign =
			Object.assign ||
			function(t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s)
						if (Object.prototype.hasOwnProperty.call(s, p))
							t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
var __rest =
	(this && this.__rest) ||
	function(s, e) {
		var t = {};
		for (let p in s) {
			if (
				Object.prototype.hasOwnProperty.call(s, p) &&
				e.indexOf(p) < 0
			) {
				t[p] = s[p];
			}
		}
		if (s != null && typeof Object.getOwnPropertySymbols === "function")
			for (
				let i = 0, p = Object.getOwnPropertySymbols(s);
				i < p.length;
				i++
			) {
				if (
					e.indexOf(p[i]) < 0 &&
					Object.prototype.propertyIsEnumerable.call(s, p[i])
				)
					t[p[i]] = s[p[i]];
			}
		return t;
	};

export const EntityRevisionHistoryTabContent = withRouter(
	({ record, resource, history, ...resttodo }) => {
		const {
			change,

			initialize,

			getState,

			batch
		} = useForm();

		const setRevisionValues = useCallback(
			(events, untilId) => {
				const { pristine } = getState();

				if (
					pristine ||
					window.confirm(
						`You have unsaved changes that will be overwritten when you load this revision into the form. Are you sure you want to continue?`
					)
				) {
					let recordCopy = { ...record };

					for (var i = 0; i < events.length; i++) {
						if (Array.isArray(events[i].diff)) {
							events[i].diff.forEach(ch => {
								revertChange(recordCopy, true, ch);
							});
						}
						if (~~events[i].id === ~~untilId) {
							break;
						}
					}

					batch(() => {
						for (const key in recordCopy) {
							change(key, recordCopy[key]);
						}
					});
					if (resource.match(/^settings\//)) {
						history.replace(`/${resource}`);
					} else {
						history.replace(`/${resource}/${record.id}`);
					}
				}
			},
			[initialize]
		);

		const filter = {
			[resource.match(/^settings\//)
				? "view"
				: `${resource}_id`]: record.id,
			type: "updated"
		};

		return (
			<Timeline loadDataIntoForm={setRevisionValues} filter={filter} />
		);
	}
);

var hiddenStyle = { display: "none" };
export const EntityRevisionHistory = function(_a) {
	var className = _a.className,
		contentClassName = _a.contentClassName,
		hidden = _a.hidden,
		icon = _a.icon,
		intent = _a.intent,
		label = _a.label,
		value = _a.value,
		rest = __rest(_a, [
			"basePath",
			"className",
			"contentClassName",
			"children",
			"hidden",
			"icon",
			"intent",
			"label",
			"margin",
			"record",
			"resource",
			"variant",
			"value"
		]);
	var location = useLocation();
	var renderHeader = function() {
		return React.createElement(
			MuiTab,
			__assign(
				{
					key: label,
					label: "Revision history",
					value: value,
					icon: icon,
					className: classnames("form-tab", className),
					component: Link,
					to: __assign(__assign({}, location), { pathname: value })
				},
				rest
			)
		);
	};
	var renderContent = function() {
		if (hidden) {
			return null;
		}
		return React.createElement(
			"span",
			{ style: hidden ? hiddenStyle : null, className: contentClassName },
			<EntityRevisionHistoryTabContent {..._a} />
		);
	};
	return intent === "header" ? renderHeader() : renderContent();
};
