import React from "react";

import { useInput } from "react-admin";
import { Avatar } from "cms-toolbox";

const AvatarInput = ({ children, ...props }) => {
    const {
        input: { value: gender }
    } = useInput(props);

    const {
        input: { value: seed }
    } = useInput({ source: "name" });

    return (
        <div style={{ display: "flex" }}>
            {children}

            <div style={{ padding: 13 }}>
                <Avatar seed={seed} gender={gender} />
            </div>
        </div>
    );
};
export default AvatarInput;
