export const validators = {
	required: v => (!v && v !== 0 ? "Required" : null),
	regex:({pattern,message})=>{
		return (v)=>{

			return !v||!v.match(new RegExp(pattern))?(message||"Incorrect format"):null}
	},
	email: v => !(v&&/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v))?"Not a valid email address":null,
	length:({min,max})=>{
		return (v)=>{
			const vt=(v||"").trim()
			if(min && vt.length>0 && vt.length<min){
				return `Minimum length is ${min} characters`
			}
			if(max && vt.length>max){
				return `maximum length is ${max} characters`
			}
			return null
		}
	}
};
