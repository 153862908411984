export const GET_LIST = 'GET_LIST';
export const GET_ONE = 'GET_ONE';
export const GET_MANY = 'GET_MANY';
export const GET_MANY_REFERENCE = 'GET_MANY_REFERENCE';
export const CREATE = 'CREATE';
export const CREATE_MANY = 'CREATE_MANY';
export const UPDATE_MANY = 'UPDATE_MANY';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const DELETE_MANY = 'DELETE_MANY';
export const POST_TO_CUSTOM = "POST_TO_CUSTOM";
export const POST_TO_AUTH = "POST_TO_AUTH";
export const GET_RESOURCE = "GET_RESOURCE";
export const GET_CUSTOM = "GET_CUSTOM";
