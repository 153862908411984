import * as React from "react";
import { Children, cloneElement, isValidElement, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "ra-core";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Typography } from "@material-ui/core";
import { Avatar } from "cms-toolbox";

import {UserShowInline} from "cms-config/resources/user"
import { MenuItem } from "@material-ui/core";




var UserMenu = function(props) {
    var _a = useState(null),
        anchorEl = _a[0],
        setAnchorEl = _a[1];
    var translate = useTranslate();
    var children = props.children,
        label = props.label,
        logout = props.logout;
    if (!logout && !children) return null;
    var open = Boolean(anchorEl);
    var handleMenu = function(event) {
        return setAnchorEl(event.currentTarget);
    };
    var handleClose = function() {
        return setAnchorEl(null);
    };
    const user_id = localStorage.getItem("user_id");

    return React.createElement(
        "div",
        null,
        React.createElement(
            Tooltip,
            { title: label && translate(label, { _: label }) },
            React.createElement(
                Button,
                {
                    "aria-label": label && translate(label, { _: label }),
                    "aria-owns": open ? "menu-appbar" : null,
                    "aria-haspopup": true,
                    color: "inherit",
                    onClick: handleMenu,
                    style:{textTransform: 'none'}
                },
                <React.Fragment>
                    

                    {user_id?<UserShowInline id={~~user_id}/>:"User"}
                </React.Fragment>
            )
        ),
        React.createElement(
            Menu,
            {
                id: "menu-appbar",
                anchorEl: anchorEl,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                },
                 getContentAnchorEl:null,
                open: open,
                onClose: handleClose
            },
            Children.map(children, function(menuItem) {
                return isValidElement(menuItem)
                    ? cloneElement(menuItem, {
                          onClick: handleClose
                      })
                    : null;
            }),
            user_id?<MenuItem
                        component="a"
                        href={`/#/user/${user_id}`}
                    >
                       Profile
                    </MenuItem>:null,
            logout
        )
    );
};
UserMenu.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,
    logout: PropTypes.element
};
UserMenu.defaultProps = {
    label: "ra.auth.user_menu"
};
export default UserMenu;
