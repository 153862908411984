const dev = process.env.NODE_ENV && process.env.NODE_ENV === "development";

export const hostname = window.location.host.split(":")[0];

const tld =
	{
		"cms.advocaathulp.com": "advoc",
		"cms-mh.evenses.com": "mh"
	}[hostname] ||
	(parts => {
		let tld = [];
		for (let i = parts.length - 1; i >= 0; i--) {
			if (parts[i].length > 3) {
				break;
			}
			tld = [parts[i], ...tld];
		}
		return tld.join(".");
	})(hostname.split("."));

export const region =
	{
		"co.uk": "uk",
		com: "nl"
	}[tld] ||
	tld ||
	"nl";
const apiBase = dev ? "//localhost:3001" : "https://api.evenses.com";

export const apiUrl = `${apiBase}/${region}`;

export const siteDomain=dev?'evenses.com':hostname.replace(/^cms\./,'')

export const siteUrl = dev?'http://localhost:5173':`https://www.${siteDomain}`;
