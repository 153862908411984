import React from "react";
import { Filter, BooleanInput } from "react-admin";

import { Edit, Create } from "cms-toolbox/ReactAdminHoc";

import { TabbedForm } from "cms-toolbox/FormHoc";
import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import List from "cms-toolbox/List";

import {
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from "cms-toolbox/inputHoc";

import ListContent from "cms-toolbox/ListContent";
import { FieldSet, FormTab } from "cms-toolbox";

import { product as schema } from "ev-schema";
import { EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";

import { ListBulkActions, ListActions } from "cms-toolbox/ListActions";
import { getPermissions } from "cms-config/authProvider";

const ProductFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn source="q" />
        <BooleanInput
            alwaysOn
            source="full-search"
            variant="outlined"
            label="Full search"
        />
        <ReferenceArrayInput
            filterToQuery={q => ({ root: "category", q })}
            sort={{ field: "length(text)" }}
            alwaysOn
            label="Category"
            reference="tree"
            source="category"
        >
            <AutocompleteArrayInput optionText="text" />
        </ReferenceArrayInput>

        <ReferenceArrayInput
            filterToQuery={q => ({ root: "genre", q })}
            sort={{ field: "length(text)" }}
            //alwaysOn
            label="Genre"
            reference="tree"
            source="genre"
        >
            <AutocompleteArrayInput optionText="text" />
        </ReferenceArrayInput>

        <ReferenceArrayInput
            filterToQuery={q => ({ root: "occasion", q })}
            sort={{ field: "length(text)" }}
            //alwaysOn
            label="Occasion"
            reference="tree"
            source="occasion"
        >
            <AutocompleteArrayInput optionText="text" />
        </ReferenceArrayInput>

        <ReferenceInput
            filterToQuery={q => ({
                q,
                product_id: {
                    operator: "!=",
                    operand: ""
                }
            })}
            sort={{ field: "length(path)" }}
            // alwaysOn
            label="Url path"
            reference="path"
            source="path"
        >
            <AutocompleteInput optionText="path" />
        </ReferenceInput>
    </Filter>
);

export class ProductList extends React.Component {


    render = () => {
      const { fullAccess } = getPermissions();
        return (
            <List
                title="Products"
                {...this.props}
                perPage={10}
                filters={<ProductFilter />}
                //pagination={null}

                bulkActionButtons={fullAccess?<ListBulkActions />:false}
                actions={<ListActions />}
            >
                <ListContent
                    editAction
                    visitAction={true}
                    mediaAction={true}
                    {...this.props}
                    showFields={[
                        { source: "title", media: "small" },
                        //  { source: "path", media: "small" },
                        { source: "published", media: "small" },

                        { source: "mainImage", label: "Image", media: "small" },
                        fullAccess&&{ source: "price", media: "medium" },
                        fullAccess&&{ source: "weight" },
                        { source: "category" },
                        fullAccess&&{ source: "created", media: "medium", type: "date" }
                    ].filter(a=>a)}
                    schema={schema}

                    //isTree
                />
            </List>
        );
    };
}

const ProductTitle = ({ record }) => {
    return (
        <span>
            {record.id
                ? record.title
                    ? record.title
                    : `Product ${record.id}`
                : `New product`}
        </span>
    );
};

const SUPPLIER_ALLOWED_FIELDS=["title","lead","body","reviews","songs","mainImage","slides"];

export const supplierProductSchema=()=>schema.filter(({source})=>~SUPPLIER_ALLOWED_FIELDS.indexOf(source))

const ProductFormFields = props => {
    const { fullAccess } = getPermissions();


    if(!props.record){
        props.record={}
    }
    if(!props.record.inherit){
        props.record.inherit=[{
            deals:[],
            faq:[]
        }]
    }


    return fullAccess ? (
        <FormTab {...props} label="Edit" contentClassName="form-tabs-vertical">
            <FieldSet
                schemaFragment={schema}
                tab="main"
                tabLabel="Main"
                entityId={props.id}
            />

            <FieldSet
                schemaFragment={schema}
                tab="details"
                tabLabel="Details"
                entityId={props.id}
            />
            <FieldSet
                schemaFragment={schema}
                tab="media"
                tabLabel="Media"
                entityId={props.id}
            />
            <FieldSet
                schemaFragment={schema}
                tab="catalog"
                tabLabel="Catalog"
                entityId={props.id}
            />

            <FieldSet
                schemaFragment={schema}
                tab="meta"
                tabLabel="Metadata"
                entityId={props.id}
            />
        </FormTab>
    ) : (
        <FormTab {...props} label="Edit">
            <FieldSet schemaFragment={supplierProductSchema()} entityId={props.id} />
        </FormTab>
    );
};

const ProductForm = props => (
    <TabbedFormHorizontal redirect={"edit"} {...props} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <ProductFormFields {...props} />

        {props?.record?.id ? <EntityRevisionHistory {...props} /> : null}
    </TabbedFormHorizontal>
);



export const ProductEdit = props => (
    <Edit title={<ProductTitle />} {...props}>
        <ProductForm
            redirect={() => {
                if (window.redirectOverride) {
                    return "/" + window.redirectOverride;
                }

                return false;
            }}
        />
    </Edit>
);

export const ProductCreate = props => (
    <Create title={<ProductTitle />} {...props}>
        <ProductForm />
    </Create>
);
