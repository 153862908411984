import {
    getTreeFromFlatData,
    getVisibleNodeCount,
    map
} from "react-sortable-tree";

const initialState = {};

export const treeReducer = (state = initialState, action) => {

    switch (action.type) {
        case "TREE_SEARCH_SET":
            if (action.payload.matches) {
                if (
                    JSON.stringify(state.search.matches) ===
                    JSON.stringify(action.payload.matches)
                ) {
                    return state;
                }
            }

            return expandFocused(action.resource, {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload
                }
            });
        case "TREE_NODE_EXPAND":
            const { expanded } = action;
            return {
                ...state,
                [action.resource]: {
                    ...state[action.resource],
                    renderProps: getRenderProps(
                        map({
                            treeData:
                                state[action.resource].renderProps.treeData,
                            getNodeKey: ({ node }) => node.id,
                            callback: ({ node }) => {
                                if (node.id === action.id) {
                                    return {
                                        ...node,
                                        expanded
                                    };
                                }
                                return node;
                            }
                        })
                    )
                }
            };
        case "TREE_UPDATED":
            return {
                ...state,
                [action.resource]: {
                    ...state[action.resource],
                    renderProps: getRenderProps(action.treeData)
                }
            };
        //case "CUSTOM_FETCH_SUCCESS":
        case "RA/CRUD_GET_LIST_SUCCESS":{
            if (action.meta.resource !== "tree") {
                return state;
            }
            /*
            const { ids = [], data = {} } = {
                ...(state[action.meta.resource] || {})
            };*/
            const ids = [],
                data = {};
            for (const row of action.payload.data) {
                // if (action.type === "RA/CRUD_GET_LIST_SUCCESS") {
                ids.push(row.id);
                //}
                data[row.id] = row;
            }

            const flatData = ids
                .map(id => ({
                    ...data[id],
                    title: data[id].text,
                    expanded: state["e" + id]
                }))
                .sort((a, b) =>
                    a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0
                );

            const treeData = getTreeFromFlatData({
                flatData,
                getParentKey: node => node.parent,
                rootKey: null
            });

            return {
                ...state,
                [action.meta.resource]: {
                    ids,
                    data,
                    renderProps: getRenderProps(treeData)
                }
            };
        }
        default:
            return state;
    }
};

function getRenderProps(treeData) {
    return {
        treeData,
        count: getVisibleNodeCount({ treeData })
    };
}

function expandFocused(resource, { search, ...state }) {
    const { searchFocusIndex = 0, matches = [] } = search;

    const nid = matches[searchFocusIndex];

    const tree = state[resource]?.renderProps?.treeData || [];

    const parents = (treeFindParents(nid, tree) || []).map(({ id }) => id);

    return {
        ...state,

        [resource]: {
            ...state[resource],
            renderProps: getRenderProps(
                map({
                    ignoreCollapsed: false,
                    treeData: state[resource].renderProps.treeData,
                    getNodeKey: ({ node }) => node.id,
                    callback: ({ node }) => {
                        if (parents.includes(node.id)) {
                            return {
                                ...node,
                                expanded: true
                            };
                        }
                        return node;
                    }
                })
            )
        },

        search
    };
}

export function treeFindParents(id, tree) {
    for (const branch of tree) {
        if (branch.id === id) {
            return [branch];
        }
        if (branch.children) {
            const sub = treeFindParents(id, branch.children);
            if (sub) {
                sub.push(branch);
                return sub;
            }
        }
    }
    return false;
}

const VKEY = "visits";

const getVisitHistoryFromCache = () => {
    const raw = localStorage.getItem(VKEY);
    if (raw) {
        try {
            const arr = JSON.parse(raw);
            if (Array.isArray(arr)) {
                return arr;
            }
        } catch (e) {}
    }

    return [];
};

const setVisitHistoryCache = arr => {
    localStorage.setItem(VKEY, JSON.stringify(arr));
};

export const visitHistoryReducer = (
    state = getVisitHistoryFromCache(),
    action
) => {
    switch (action.type) {
        case "@@router/LOCATION_CHANGE": {
            if (action.payload.action === "PUSH"||action.payload.isFirstRendering) {
                const { location } = action.payload;

                const p = location.pathname.replace(/^\//, "");
                if(state[0]!==p){

                const parts=p.split('/')
                if (parts.length>=2&&parts[0]!=='user') {
                 
                    const newState = [
                        p,
                        ...getVisitHistoryFromCache().filter(pt => pt !== p)
                    ].slice(0,10);

                    setVisitHistoryCache(newState);

                    return newState;
                }
                }
            }
        }
        default:
    }
    return state;
};




export const revisionAmountReducer = (
    state ={
        updateCount:0
    },
    action
) => {
    switch (action.type) {
        case "RA/CRUD_GET_LIST_SUCCESS": 
        case "RA/CRUD_UPDATE_SUCCESS": 
        {



            if (action.meta.resource==='revision') {
                return {
                    ...state,
                    updateCount:state.updateCount+1
                }
            }
        }
        default:
    }
    return state;
};
