import * as React from "react";
import { Children, cloneElement, useRef } from "react";
import PropTypes from "prop-types";
import MoveIcon from "@material-ui/icons/Reorder";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Typography from '@material-ui/core/Typography';
import get from "lodash/get";

import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useTranslate, ValidationError } from "ra-core";
import classNames from "classnames";

import { FormInput } from "react-admin";

///////////

import {
    SortableContainer,
    SortableElement,
    sortableHandle
} from "react-sortable-hoc";

const useStyles = makeStyles(
    theme => ({
        root: {
            padding: 0,
            marginBottom: 0,
            "& > li:last-child": {
                borderBottom: "none"
            }
        },
        line: {
            display: "flex",
            listStyleType: "none",
            borderBottom: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down("xs")]: { display: "block" },
            "&.fade-enter": {
                opacity: 0.01,
                transform: "translateX(100vw)"
            },
            "&.fade-enter-active": {
                opacity: 1,
                transform: "translateX(0)",
                transition: "all 500ms ease-in"
            },
            "&.fade-exit": {
                opacity: 1,
                transform: "translateX(0)"
            },
            "&.fade-exit-active": {
                opacity: 0.01,
                transform: "translateX(100vw)",
                transition: "all 500ms ease-in"
            }
        },
        index: {
            width: "3em",
            paddingTop: "1em",
            cursor:"move",
            [theme.breakpoints.down("sm")]: { display: "none" }
        },
        form: { flex: 2 },
        action: {
            paddingTop: "0.5em"
        },
        leftIcon: {
            marginRight: theme.spacing(1)
        }
    }),
    { name: "RaSimpleFormIterator" }
);

const DefaultAddButton = props => {
    const classes = useStyles(props);
    const translate = useTranslate();
    return (
        <Button size="small" {...props}>
            <AddIcon className={classes.leftIcon} />
            {translate("ra.action.add")}
        </Button>
    );
};

const DefaultRemoveButton = props => {
    const classes = useStyles(props);
    const translate = useTranslate();
    return (
        <Button size="small" {...props}>
            <CloseIcon className={classes.leftIcon} />
            {translate("ra.action.remove")}
        </Button>
    );
};

const SortableItem = SortableElement(
    ({ children, index }) =>
        children
);

const SortableList = SortableContainer(({ items, className }) => {
    return (
        <TransitionGroup className={className} component={'ul'}>
           {items}
        </TransitionGroup>
    );
});

 const DragHandle = sortableHandle(({className}) => (
        <div className={className}>
            <MoveIcon />
        </div>
    ));

const SimpleFormIterator = props => {
    const {
        addButton = <DefaultAddButton />,
        removeButton = <DefaultRemoveButton />,
        basePath,
        children,
        fields,
        meta: { error, submitFailed },
        record,
        resource,
        source,
        //disableAdd,
        // disableRemove,
        variant,
        margin,
        TransitionProps,
        defaultValue,
        min,
        max,
        axis,
        mode,
        label
    } = props;
    const classes = useStyles(props);

    const sortContRef = useRef(null);

    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
            ? defaultValue.length
            : 0
    );

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    const onDragEnd = ({ oldIndex, newIndex }) => {
        /*   console.log(result)
        if (!result.destination) {
            return;
        }


        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = ids.current.splice(startIndex, 1);
        ids.current.splice(endIndex, 0, removed);
        fields.move(startIndex, endIndex);*/

        const [removed] = ids.current.splice(oldIndex, 1);
        ids.current.splice(newIndex, 0, removed);
        fields.move(oldIndex, newIndex);
    };

    const removeField = index => () => {
        ids.current.splice(index, 1);
        fields.remove(index);
    };

    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determining if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.

    const addField = () => {
        ids.current.push(nextId.current++);
        fields.push({});
    };

    // add field and call the onClick event of the button passed as addButton prop
    const handleAddButtonClick = originalOnClickHandler => event => {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    // remove field and call the onClick event of the button passed as removeButton prop
    const handleRemoveButtonClick = (
        originalOnClickHandler,
        index
    ) => event => {
        removeField(index)();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    const disableRemove = min && fields.length <= min;

    const records = get(record, source);

    const singleElementOnly = min === 1 && max === 1;

   

    const items = fields.map((member, index) => (
        <SortableItem index={index} key={ids.current[index]}>
            <CSSTransition timeout={500} classNames="fade" {...TransitionProps}>
                <li className={classes.line + " array-row"}>
                    {!singleElementOnly ? (
                        <DragHandle className={classes.index} />
                    ) : null}

                    <section className={classes.form}>
                        {Children.map(children, (input, index2) =>
                            input ? (
                                <FormInput
                                    basePath={input.props.basePath || basePath}
                                    input={cloneElement(input, {
                                        source: input.props.source
                                            ? `${member}.${input.props.source}`
                                            : member,
                                        index: input.props.source
                                            ? undefined
                                            : index2,
                                        label:
                                            typeof input.props.label ===
                                            "undefined"
                                                ? input.props.source
                                                    ? `resources.${resource}.fields.${
                                                          input.props.source
                                                      }`
                                                    : undefined
                                                : input.props.label
                                    })}
                                    record={(records && records[index]) || {}}
                                    resource={resource}
                                    variant={variant}
                                    margin={margin}
                                />
                            ) : null
                        )}
                    </section>
                    {!disableRemove && (
                        <span className={classes.action}>
                            {cloneElement(removeButton, {
                                onClick: handleRemoveButtonClick(
                                    removeButton.props.onClick,
                                    index
                                ),
                                className: classNames(
                                    "button-remove",
                                    `button-remove-${source}-${index}`
                                )
                            })}
                        </span>
                    )}
                </li>
            </CSSTransition>
        </SortableItem>
    ));

    const cont= (

        <React.Fragment>
            {submitFailed && typeof error !== "object" && error && (
                <FormHelperText error style={{ marginTop: 15 }}>
                    <ValidationError error={"error"} />
                </FormHelperText>
            )}
            <div className="sort-ghost-cont" ref={sortContRef} />

            <SortableList
                useDragHandle={true}
                helperContainer={sortContRef.current}
                axis={axis}
                onSortEnd={onDragEnd}
                items={items}
                className={classes.root}
                key={fields.length.toString()}
            />
            {(!max || items.length < max) && (
                <li className={classes.line}>
                    <span className={classes.action}>
                        {cloneElement(addButton, {
                            onClick: handleAddButtonClick(
                                addButton.props.onClick
                            ),
                            className: classNames(
                                "button-add",
                                `button-add-${source}`
                            )
                        })}
                    </span>
                </li>
            )}
        </React.Fragment>
    );

    if(mode==='group'){
        return <details className={"group-wrapper"}>
            <summary className={"group-summary"}>
                <Typography variant="button" style={{display:"inline"}}>{label}</Typography>
                </summary>
            <div className={"group-cont"}>
                {cont}
            </div>
        </details>
    }
return cont;

    // return fields ? /*singleElementOnly*/null?fieldMap(false):(
    //     <SortableList axis="xy" key={fields.length.toString()} onSortEnd={onDragEnd} items={fields}/>
    // ) : null;
};

SimpleFormIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false
};

SimpleFormIterator.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.any,
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    // @ts-ignore
    fields: PropTypes.object,
    meta: PropTypes.object,
    // @ts-ignore
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({})
};

export default SimpleFormIterator;
