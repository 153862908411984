import React from "react"
import { Layout } from 'react-admin';
//import  Layout  from '.LayoutOverridden';
import AppBar from './AppBar';
import Menu from './Menu';
import Notification from './Notification';
import { getPermissions } from "cms-config/authProvider";


const AppLayout = (props) => {

	const {fullAccess}=getPermissions();

	return (<Layout
    {...props}
      className={'access-'+(getPermissions().fullAccess?'full':'limited')}
    appBar={AppBar}
    menu={Menu}
    notification={Notification}
/>)
};


export default AppLayout;