import React from "react";


import { Edit } from "cms-toolbox/ReactAdminHoc";

import { FieldSet } from "cms-toolbox/FormContent";

import { front as schema } from "ev-schema";




import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";

export const FrontEdit = props => (
	<Edit title={"Front page"} {...props} id={"front"}>

    <TabbedFormHorizontal {...props} redirect={false}  warnWhenUnsavedChanges={false} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit" contentClassName="form-tabs-vertical">
          <FieldSet schemaFragment={schema} tab="banner" tabLabel="Banner" />

			<FieldSet
				schemaFragment={schema}
				tab="order_process"
				tabLabel="Order process"
			/>

			<FieldSet
				schemaFragment={schema}
				tab="product_selection"
				tabLabel="Product selection"
			/>


			<FieldSet schemaFragment={schema} tab="text" tabLabel="Text" />

			<FieldSet schemaFragment={schema} tab="meta" tabLabel="Metadata" />
        </FormTab>
         <EntityRevisionHistory {...props} />
    </TabbedFormHorizontal>



	</Edit>
);
