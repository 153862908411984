import React from "react";
import { SelectArrayInput, SelectInput } from "react-admin";
import Chip from "@material-ui/core/Chip";
import Color from "color";

export class ChipArrayInput extends React.Component {
	render() {
		const { choices } = this.props;
		return (
			<SelectArrayInput
				options={{
					renderValue: selected => (
						<ChipList selected={selected} choices={choices} />
						/*<div>
							{selected.map(val => {
								const found = choices.find(c => c.id === val);
								if (found) {
									return (
										<Chip
											 key={}
											label={found.name}
											style={{ background: found.color }}
										/>
									);
								}
							})}
						</div>*/
					)
				}}
				{...this.props}
			/>
		);
	}
}

export class ChipInput extends React.Component {
	render() {
		const { choices } = this.props;
		return (
			<SelectInput
				optionText={option => (
					<ChipList choices={choices} selected={option.id} />
				)}
				{...this.props}
			/>
		);
	}
}

export class ChipList extends React.Component {
	render() {
		const { choices, selected } = this.props;
		return (
			<div>
				{(Array.isArray(selected) ? selected : [selected]).map(val => {
					const found = choices&&choices.find(c => c.id === val);
					if (found) {
						return (
							<Chip
								key={found.id.toString()}
								label={found.name}
								style={found.color?{
									background: found.color,
									color: Color(found.color).darken(.75).saturate(1)
								}:null}
							/>
						);
					}
					return null;
				})}
			</div>
		);
	}
}
