import React, { useRef } from "react";
import { connect } from "react-redux";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";

import { useNotify, useDataProvider, EditButton } from "react-admin";

import { DndProvider, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import TreeNodeRenderer from "./TreeNodeRenderer";

const RNDContext = createDndContext(HTML5Backend);

function useDNDProviderElement(props) {
  const manager = useRef(RNDContext);

  if (!props.children) return null;

  return (
    <DndProvider manager={manager.current.dragDropManager}>
      {props.children}
    </DndProvider>
  );
}

function DragAndDrop(props) {
  const DNDElement = useDNDProviderElement(props);
  return <React.Fragment>{DNDElement}</React.Fragment>;
}

const Tree = connect(({ tree }, { resource }) => ({
  ...(tree[resource]?.renderProps || {}),
  search: tree.search || {}
}))(({ dispatch, schema, resource, treeData, count, search }) => {
  const onToggle = ({ node, expanded: mustExpand }) => {
    dispatch({
      type: "TREE_NODE_EXPAND",
      resource,
      id: node.id,
      expanded: mustExpand
    });
  };

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const move = ({ node, nextParentNode, nextTreeIndex, treeData }) => {
    dispatch({
      type: "TREE_UPDATED",
      resource,
      treeData
    });

    const updateAllSiblings = (nextParentNode
      ? nextParentNode.children
      : treeData
    ).map((node, i) => {
      let data = {};
      for (const field of schema) {
        data[field.source] = node[field.source];
      }
      data.weight = i + 1;
      data.parent = nextParentNode?.id || null;

      return { id: node.id, data };
    });

    dataProvider
      .updateMany(resource, {
        ids: updateAllSiblings.map(({ id }) => id),
        data: updateAllSiblings.map(({ data }) => data)
      })
      .then(response => {
        notify("Tree structure updated");
      })
      .catch(error => {
        notify(`Error updating: ${error.message}`, "warning");
      });
  };

  const { searchString = "", searchFocusIndex, matches = [] } = search;

  if (!treeData) {
    return null;
  }

  // Case insensitive search of `node.title`
  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

  return (
    <DragAndDrop>
      <SortableTree
        generateNodeProps={({ node }) => {
          const set = {
            buttons: [<EditButton basePath={resource} record={node} />]
          };
          if (searchString) {
            const isSearchMatch = matches.indexOf(node.id);

            set.isSearchFocus = isSearchMatch === searchFocusIndex;
            set.isSearchMatch = !!~isSearchMatch;
          }
          return set;
        }}
        nodeContentRenderer={TreeNodeRenderer}
        treeData={treeData}
        onVisibilityToggle={onToggle}
        onChange={w => {}}
        onMoveNode={move}
        style={{ height: count * 62 }}
        searchMethod={customSearchMethod}
        searchQuery={searchString}
        searchFocusOffset={searchFocusIndex}
        searchFinishCallback={matches => {
          if (searchString) {
            dispatch({
              type: "TREE_SEARCH_SET",
              resource,
              payload: {
                matches: matches.map(({ node }) => node.id),
                searchFocusIndex:
                  matches.length > 0
                    ? (searchFocusIndex || 0) % matches.length
                    : 0
              }
            });
          }
        }}
      />
    </DragAndDrop>
  );
});

export default Tree;
