import React from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import EventItem from './EventItem';

const styles = {
    root: {
      
    },
};

const EventListView = ({ events = [], classes, loadDataIntoForm }) => (
    <Card className={classes.root}>
        <List>
            {events.map((event,i) => (
                <EventItem event={event} key={event.id} loadDataIntoForm={loadDataIntoForm?()=>loadDataIntoForm(event.id):null} />
            ))}
        </List>
    </Card>
);

const EventList = withStyles(styles)(EventListView);

export default EventList;