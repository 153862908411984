import React from "react";
import {
    ReferenceInput,
    NumberInput,
    SelectInput,
    TextInput,
    DateTimeInput,
    ArrayInput,
    DateInput,
    AutocompleteInput,
   // ImageInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    PasswordInput,
    CheckboxGroupInput,
    FormTab
} from "react-admin";
import { ColorInput } from "cms-toolbox/ColorInput";
import { AvatarInput } from "cms-toolbox";
import BooleanInput from "./BooleanInput";
import OrderedAutocompleteArrayInput from "cms-toolbox/OrderedAutocompleteArrayInput";
import { Divider, Tab } from "@material-ui/core";
import { useInput } from "ra-core";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import QuickCreate from "./QuickCreate";

import { validators } from "./helpers/form";
//import RichTextInput from "ra-input-rich-text";
import OrderedFormIterator from "./OrderedFormIterator";
import MarkDownInput from "./MarkDownInput";
import SliderInput from "./SliderInput";
import TreeTreeSelect from "./TreeTreeSelect";

import { DivNoProps, ImageBucketUploader } from ".";

const OptionRenderer = choice =>
    choice.record && choice.record.id ? (
        <span>
            <img
                className="option-image"
                src={`https://storage.googleapis.com/evenses/icons/${choice.record.id}`}
            />
            {choice.record.id.split(".")[0]}
        </span>
    ) : (
        "- none -"
    );

const imageAutocompleteProps = {
    optionText: <OptionRenderer />,
    inputText: choice => (choice ? choice.id.split(".")[0] : ""),
    matchSuggestion: (filterValue, suggestion) => true
};

export const FieldSet = topProps => {
    const [{ scrollKey, scrollIndex }, setScroll] = React.useState({
        scrollIndex: -1,
        scrollKey: 1
    });


    const {
        source: parentSource,
        schemaFragment,
        tab,
        tabLabel,
        entityId,
        filter = false,

        isSubForm,
        legend,
        tabIndex,
        tabTotal
    } = topProps;


    if(topProps.record&&schemaFragment.find(({source})=>source==='inherit')){
        if(!topProps.record.inherit){
            topProps.record.inherit=[{
                deals:[],
                faq:[]
            }]
        }
    }


    let schemaUse = schemaFragment.filter(col => {
        if (typeof tab !== "undefined") {
            return col.tab === tab || (!tab && !col.tab);
        }
        if (col.tab === "_NONE_") {
            return false;
        }
        if (!filter || !Array.isArray(filter)) {
            return true;
        }

        return ~filter.indexOf(col.source);
    });

    if (legend) {
        let newSchemaUse = [];
        for (const { image, fields } of legend) {
            newSchemaUse.push({
                type: "legend",
                image
            });
            for (const fieldKey of fields) {
                const foundField = schemaUse.find(
                    ({ source }) => source.split(":").pop() === fieldKey
                );
                if (foundField) {
                    newSchemaUse.push(foundField);
                }
            }
            newSchemaUse.push({
                type: "divider"
            });
        }

        for (const field of schemaUse) {
            if (!newSchemaUse.find(({ source }) => source === field.source)) {
                newSchemaUse.push(field);
            }
        }

        schemaUse = newSchemaUse;
    }

    const ret = schemaUse.map((col, i) => {
        const props = {
            ...(i === 0 && !isSubForm && (!tab || tab === "main")
                ? { autoFocus: true }
                : {}),
            source: (parentSource ? parentSource + "." : "") + col.source,
            label: col.mode==='group'?false:col.label,
            key: col.source,

            helperText: col.help,
            validate: (col.validate || []).map(v => {
                if (typeof v === "string") {
                    return validators[v];
                }

                return validators[v.type](v);
            }),
            ...col.props
        };

        const content = (() => {
            switch (col.type) {
                case "legend": {
                    return (
                        <img
                            alt="legend"
                            className="legend-img"
                            {...col.image}
                        />
                    );
                }
                case "divider": {
                    return <Divider style={{ margin: "20px 0" }} />;
                }

                case "array": {
                    /*const amt = (siblingData[col.source] || []).length;
                            const disableRemove =
                                typeof col.min === "number" && amt <= col.min;

                            if (typeof col.min === "number" && amt < col.min) {
                                siblingData[col.source] = [
                                    ...(siblingData[col.source] || []),
                                    ...Array(col.min - amt).fill(null)
                                ];
                            }*/
                    let schemaFragment = col.sub;
                    let className;
                    let axis = "y";

                    if (col.mode === "mediaplayer") {
                        axis = "xy";
                        className = "grid-array";
                    } else if (col.min === 1 && col.max === 1) {
                        className = "single-element-array";
                        //    Iterator=SimpleFormIterator
                    }

                    const Component =
                        col.xor && false ? FieldSetExclusive : FieldSet;

                    let defaultValue = col.min ? Array(col.min).fill({}) : null;

                    const { helperText, ...pass } = props;
                    return (
                        <ArrayInput
                            {...pass}
                            className={className}
                            defaultValue={defaultValue}
                        >
                            <OrderedFormIterator
                                mode={col.mode}
                                min={col.min}
                                max={col.max}
                                label={col.label}
                                axis={axis}
                            >
                                <Component
                                    schemaFragment={schemaFragment}
                                    filter={false}
                                    tab={false}
                                    isSubForm={true}
                                />
                                {/*} <FormDataConsumer>
                                            {({getSource}) =>
                                            <FieldSet
                                                getSource={getSource}
                                                schemaFragment={schemaFragment}
                                                filter={false}
                                                tab={false}
                                                isSubForm={true}
                                            /> }

                                        </FormDataConsumer>*/}
                            </OrderedFormIterator>
                        </ArrayInput>
                    );
                }
                case "boolean":
                    return (
                        <BooleanInput {...props} className="boolean-input" />
                    );

                case "reference":
                    const autocomplete =
                        col.reference.inputType === "autocomplete";

                    let children;
                    if (autocomplete) {
                        const fieldProps = {
                            helperText: col.help,
                            optionText: col.reference.optionText,
                            inputText: col.reference.inputText,
                            matchSuggestion: col.reference.matchSuggestion,

                            shouldRenderSuggestions: value =>
                                value && value.trim().length >= 1,

                            ...(col.reference.images
                                ? imageAutocompleteProps
                                : {})
                        };

                        children = col.multiple ? (
                            col.sortable ? (
                                <OrderedAutocompleteArrayInput
                                    {...fieldProps}
                                />
                            ) : (
                                <AutocompleteArrayInput {...fieldProps} />
                            )
                        ) : (
                            <AutocompleteInput {...fieldProps} />
                        );
                    } else {
                        const fieldProps = {
                            helperText: col.help,
                            root: col.root,
                            exclude: entityId || null,
                            optionText: col.reference.optionText
                        };

                        children = col.multiple ? (
                            // <SelectArrayInput {...fieldProps} />
                            <TreeTreeSelect {...fieldProps} multiple={true} />
                        ) : (
                            <TreeTreeSelect {...fieldProps} />
                        );
                    }

                    const containerProps = {
                        ...(col.reference.images
                            ? {}
                            : {
                                  filterToQuery: searchText => ({
                                      [col.reference.optionText]: {
                                          operand: "%" + searchText + "%",
                                          operator: "LIKE"
                                      }
                                  })
                              }),
                        filter: col.filter,
                        perPage: autocomplete ? 10 : 100000,
                        ...props,
                        resource: col.reference.resource,
                        reference: col.reference.resource,
                        children
                    };
                    delete containerProps.helperText;

                    const input = col.multiple ? (
                        <ReferenceArrayInput {...containerProps} />
                    ) : (
                        <ReferenceInput allowEmpty {...containerProps} />
                    );

                    if (col.reference.quickCreate) {
                        return [
                            input,
                            <QuickCreate
                                key="quickCreate"
                                source={props.source}
                                multiple={col.multiple}
                                resource={col.reference.resource}
                            />
                        ];
                    } else {
                        return input;
                    }

                case "image":
                    return (
                        <div className="image-input-with-alt">
                            <ImageBucketUploader
                                {...props}
                                source={props.source + ".uri"}
                                maxSize={
                                    ~~process.env.REACT_APP_MAX_UPLOAD_SIZE ||
                                    null
                                }
                                accept={
                                    col.accept
                                        ? Object.keys(col.accept).join(", ")
                                        : "image/*"
                                }
                            />

                            <TextInput
                                label="Alt text"
                                source={props.source + ".alt"}
                            />
                        </div>
                    );
/*
                    return (
                        <div className="image-input-with-alt">
                            <ImageInput
                                {...props}
                                maxSize={
                                    ~~process.env.REACT_APP_MAX_UPLOAD_SIZE ||
                                    null
                                }
                                source={props.source + ".new"}
                                accept={
                                    col.accept
                                        ? Object.keys(col.accept).join(", ")
                                        : "image/*"
                                }
                                placeholder={
                                    <ImageEditor
                                        transforms={col.transforms}
                                        col={col}
                                        source={props.source}
                                    />
                                }
                            >
                                <React.Fragment />
                            </ImageInput>
                            <TextInput
                                label="Alt text"
                                source={props.source + ".alt"}
                            />
                        </div>
                    );*/

                case "select":
                    const selInp = (
                        <SelectInput
                            {...props}
                            choices={col.choices.map(choice => {
                                if (!choice.icon) {
                                    return choice;
                                }
                                return {
                                    ...choice,
                                    name: (
                                        <span>
                                            <img
                                                alt=""
                                                style={{
                                                    verticalAlign: "middle",
                                                    width: "1ex",
                                                    height: "1ex",
                                                    margin: "0 .6em",
                                                    transform: "scale(3)"
                                                }}
                                                src={choice.icon}
                                            />{" "}
                                            {choice.name}
                                        </span>
                                    )
                                };
                            })}
                        />
                    );

                    if (col.mode === "avatar") {
                        return <AvatarInput {...props}>{selInp}</AvatarInput>;
                    }

                    return selInp;
                case "slider":
                    const { helperText, ...pass } = props;
                    return <SliderInput {...col} {...pass} />;
                case "number":
                    if(props.key==='evo_id'){
                        return <EvoIdField {...props} />
                    }
                    return <NumberInput {...props} />;
                case "date":
                    return <DateInput {...props} />;
                case "markdown":
                    return <MarkDownInput {...props} />;

                case "datetime":
                    return <DateTimeInput {...props} />;
                case "longtext":
                    return (
                        <TextInput
                            {...props}
                            multiline={col.multiline}
                            className="long-text-input"
                            type={col.type}
                        />
                    );
                case "checkboxes":
                    return (
                        <CheckboxGroupInput {...props} choices={col.choices} />
                    );
                case "password":
                    return <PasswordInput {...props} type={col.type} />;
                case "color":
                    return (
                        <ColorInput
                            {...props}
                            defaultColor={col.defaultColor}
                        />
                    );

                default:
                  

                    return (
                        <TextInput
                            multiline={col.multiline}
                            {...props}
                            type={col.type}
                        />
                    );
            }
        })();

        return (
            <DivNoProps
                key={props.key}
                className={`input-cont input-cont-${col.type}`}
            >
                {content}
            </DivNoProps>
        );
    });
    // .map(f => console.log(f.key)||(rest.schemaFragment ? f : <div key={f.key}>{f}</div>));

    // const ret=<FormDataConsumer>{_fieldSet}</FormDataConsumer>

    if (tab) {
        return (
            <React.Fragment>
                <div
                    className="form-tab-title"
                    style={{
                        "--offset": tabIndex,
                        "--offsetBottom": tabTotal - tabIndex
                    }}
                >
                    <Tab
                        label={tabLabel || tab}
                        color="secondary"
                        onClick={() =>
                            setScroll({
                                scrollIndex: tabIndex,
                                scrollKey: scrollKey + 1
                            })
                        }
                        className="form-tab-title-text"
                    />
                </div>

                <div className="form-tab-content">
                    {scrollIndex === tabIndex ? (
                        <ScrollIntoViewIfNeeded
                            options={{
                                behavior: "smooth",
                                block: "start"
                            }}
                            key={scrollKey}
                            className="form-tab-scroller"
                        >
                            <div />
                        </ScrollIntoViewIfNeeded>
                    ) : null}
                    <FormTab label={tabLabel || tab}>{ret}</FormTab>
                </div>
            </React.Fragment>
        );
    }
    return ret;
};



const EvoIdField = props => {
    const {
        input: { value }
    } = useInput(props);


    const href=((typeof value==="string"||typeof value==="number")&&value?value:"").toString().trim()?`https://evobooker.com/admin/products/edit/${value.toString().trim()}`:null;

    return (<React.Fragment>
        {href&&<div><a href={href} target="_blank">{href}</a></div>}
        <NumberInput {...props} />
      
    </React.Fragment>);
};

const FieldSetExclusive = props => {
    const {
        input: { value }
    } = useInput(props);

    let schemaFragment = [...props.schemaFragment];
    const filledFields = Object.keys(value).filter(k => value[k]);
    if (filledFields.length) {
        schemaFragment = schemaFragment.filter(({ source }) =>
            filledFields.includes(source)
        );
    }

    return <FieldSet {...props} schemaFragment={schemaFragment} />;
};
