import * as React from "react";
import { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import {
	AppBar as MuiAppBar,
	IconButton,
	Toolbar,
	Tooltip,
	Typography,
	useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { toggleSidebar, useTranslate } from "ra-core";
import LoadingIndicator from "./LoadingIndicator";
import UserMenu from "./UserMenu";
import CountryMenu from "./CountryMenu";
import VisitHistory from "./VisitHistory";
import {region} from "cms-config/domain"

var __assign =
	(this && this.__assign) ||
	function() {
		__assign =
			Object.assign ||
			function(t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s)
						if (Object.prototype.hasOwnProperty.call(s, p))
							t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
var __rest =
	(this && this.__rest) ||
	function(s, e) {
		var t = {};
		for (let p in s){
			if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0){
				t[p] = s[p];
			}
		}
		if (s != null && typeof Object.getOwnPropertySymbols === "function")
			for (
				let i = 0, p = Object.getOwnPropertySymbols(s);
				i < p.length;
				i++
			) {
				if (
					e.indexOf(p[i]) < 0 &&
					Object.prototype.propertyIsEnumerable.call(s, p[i])
				)
					t[p[i]] = s[p[i]];
			}
		return t;
	};

var useStyles = makeStyles(
	function(theme) {
		return {
			toolbar: {
				paddingRight: 24
			},
			menuButton: {
				marginLeft: "0.5em",
				//marginRight: "0.5em"
			},

			menuButtonIconClosed: {
				transition: theme.transitions.create(["transform"], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				transform: "rotate(0deg)"
			},
			menuButtonIconOpen: {
				transition: theme.transitions.create(["transform"], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				transform: "rotate(180deg)"
			},
			title: {
				marginLeft:"0.5em",
				flex: 1,
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				overflow: "hidden"
			}
		};
	},
	{ name: "RaAppBar" }
);
/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {ReactNode} children React node/s to be render as children of the AppBar
 * @param {Object} classes CSS class names
 * @param {string} className CSS class applied to the MuiAppBar component
 * @param {string} color The color of the AppBar
 * @param {Component} logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} open State of the <Admin/> Sidebar
 * @param {Element} userMenu A custom user menu component for the AppBar. <UserMenu/> component by default
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *        </AppBar>
 *    );
 *};
 */
var AppBar = function(props) {
	var children = props.children,

		className = props.className,
		_a = props.color,
		color = _a === void 0 ? "secondary" : _a,
		logout = props.logout,
		open = props.open,
	
		userMenu = props.userMenu,
		rest = __rest(props, [
			"children",
			"classes",
			"className",
			"color",
			"logout",
			"open",
			"title",
			"userMenu"
		]);
	var classes = useStyles(props);
	var dispatch = useDispatch();
	var isXSmall = useMediaQuery(function(theme) {
		return theme.breakpoints.down("xs");
	});
	var translate = useTranslate();
	return React.createElement(
			MuiAppBar,
			__assign({ className: className, color: color }, rest),
			React.createElement(
				Toolbar,
				{
					disableGutters: true,
					variant: isXSmall ? "regular" : "dense",
					className: classes.toolbar
				},
				React.createElement(
					Tooltip,
					{
						title: translate(
							open
								? "ra.action.close_menu"
								: "ra.action.open_menu",
							{
								_: "Open/Close menu"
							}
						),
						enterDelay: 500
					},
					React.createElement(
						IconButton,
						{
							color: "inherit",
							onClick: function() {
								return dispatch(toggleSidebar());
							},
							className: classNames(classes.menuButton)
						},
						React.createElement(MenuIcon, {
							classes: {
								root: open
									? classes.menuButtonIconOpen
									: classes.menuButtonIconClosed
							}
						})
					)
				),
				React.createElement(VisitHistory),
				Children.count(children) === 0
					? React.createElement(Typography, {
							variant: "h6",
							color: "inherit",
							className: classes.title,
							id: "react-admin-title"
					  })
					: children,
				
				React.createElement(LoadingIndicator, null),
				region!=='advoc'?React.createElement(CountryMenu):null,

				cloneElement(userMenu, { logout: logout }),
			)
		)
};
AppBar.propTypes = {
	children: PropTypes.node,
	// @ts-ignore
	classes: PropTypes.object,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		"default",
		"inherit",
		"primary",
		"secondary",
		"transparent"
	]),
	logout: PropTypes.element,
	open: PropTypes.bool,
	userMenu: PropTypes.element
};
AppBar.defaultProps = {
	userMenu: React.createElement(UserMenu, null)
};
export default AppBar;
