import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "ra-core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { MenuItem } from "@material-ui/core";
import { region } from "cms-config/domain";

const countries = {
   // at: { flag: "austria", name: "Austria" },
   // be: { flag: "belgium", name: "Belgium" },
    dk: { flag: "denmark", name: "Denmark" },

    fr: { flag: "france", name: "France" },
    de: { flag: ["germany","austria","switzerland"], name: "DE+AT+CH" },

    nl: { flag: ["netherlands","belgium"], name: "NL+BE", tld: "com" },

    es: { flag: "spain", name: "Spain" },
    se: { flag: "sweden", name: "Sweden" },
    //ch: { flag: "switzerland", name: "Switzerland" },

    uk: { flag: "united-kingdom", name: "United Kingdom", tld: "co.uk" }
};
const country = countries[region] ? region : "nl";

const activeCountry = countries[country];
const otherCountries = { ...countries };
delete otherCountries[country];

var CountryMenu = function(props) {
    var _a = useState(null),
        anchorEl = _a[0],
        setAnchorEl = _a[1];
    var translate = useTranslate();
    var label = props.label;

    var open = Boolean(anchorEl);
    var handleMenu = function(event) {
        return setAnchorEl(event.currentTarget);
    };
    var handleClose = function() {
        return setAnchorEl(null);
    };
    return React.createElement(
        "div",
        null,
        React.createElement(
            Tooltip,
            { title: activeCountry.name },
            React.createElement(
                IconButton,
                {
                    "aria-label": label && translate(label, { _: label }),
                    "aria-owns": open ? "menu-appbar" : null,
                    "aria-haspopup": true,
                    color: "inherit",
                    onClick: handleMenu
                },
                <React.Fragment>

                    <Flags alt={activeCountry.name} flag={activeCountry.flag} className="MuiSvgIcon-root" />
                </React.Fragment>
            )
        ),
        React.createElement(
            Menu,
            {
                id: "menu-appbar",
                anchorEl: anchorEl,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                },
                 getContentAnchorEl:null,
                open: open,
                onClose: handleClose
            },
            Object.keys(otherCountries).map(code => {
                const { name, flag, tld } = countries[code];

                return (
                    <MenuItem
                        component="a"
                        key={flag}
                        href={`https://cms.evenses.${tld || code}`}
                        target="_blank"
                    >
                        <Flags flag={flag} className="MuiSvgIcon-root" />

                        <span style={{ marginLeft: 10 }}> {name}</span>

                    </MenuItem>
                );
            })
        )
    );
};

const Flags=function({flag,className,style,alt}){
    return (Array.isArray(flag)?flag:[flag]).map((f,i)=><img
        className={className}
        style={i>0?{marginLeft:"-5px"}:null}
        alt={alt}
        src={`/icons/flags/${f}.svg`}
        key={f}

    />)
}

CountryMenu.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,

    icon: PropTypes.node
};
CountryMenu.defaultProps = {
    label: "ra.auth.user_menu",
    icon: React.createElement(AccountCircle, null)
};
export default CountryMenu;
